import React from "react";
import { Col, Row } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import './style.css';
function PrivacyPolicy () {
    return (
      <>
      <Header />
      <Row>
        <Col offset={2}>
          <Row>
            <Col span={24}>
              <h2><b>Privacy Policy</b></h2>
            </Col>
            <Col  xs={{ span: 22, offset: 0, order:2 }} lg={{ span: 22, offset: 0, order:1 }} >
              <div className="mt-3 about-paragraph text-justify">
                <p> Privacy policy goes here</p>
                <p>Privacy policy goes here</p>
              </div>
            </Col>
      </Row>
      
      </Col>
      </Row>
      <Footer />

</>
    );
  }
  
  export default PrivacyPolicy;