import React from "react";
import { Card, Col, Row } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import webtool from "../../images/homepage/web_tool.png";
import usdalogo from "../../images/homepage/usda_logo.png";
import pilotstates from "../../images/homepage/pilot_states.png";
import "./style.css";

function Home() {
  return (
    <>
      <Header />
      <div className="sliderimg">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
            <div className="home-header-title">
              <h1 className="crpheader">CRP Menu Tool</h1>
              <div className="crpslidertext">
                <div>
                  <p className="subtitle">
                    Find conservation practices that fit your goals
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <img src={webtool} className="img80" />
                </div>
                <h3 className="homepage-card-content">
                  The CRP Menu Tool is an online platform designed to help users
                  explore and learn more about conservation practices offered
                  through the Conservation Reserve Program (CRP) of the U.S.
                  Farm Bill. Users can find conservations practices based on
                  landowner interests and location, get soil rental rate
                  estimates, check practice eligibility, and learn how to
                  enroll.
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a href="/CRPMenu" className="btn btn-primary big-maroon-btn">
                  Get To Know Our Project
                </a>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <a href="https://www.usda.gov/" target="_blank">
                    <img src={usdalogo} className="img80" />
                  </a>
                </div>
                <h3 className="homepage-card-content">
                  The Conservation Reserve Program (CRP) is an initiative
                  implemented by the United States Department of Agriculture
                  (USDA) that aims to enhance environmental quality through the
                  conservation of sensitive lands. CRP is playing a crucial role
                  in preserving biodiversity, mitigating climate change impacts,
                  and sustaining rural economies.
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a href="/CRPBasics" className="btn btn-primary big-maroon-btn">
                  Learn More About CRP
                </a>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <img src={pilotstates} className="img80" />
                </div>
                <h3 className="homepage-card-content">
                  Based on the location of farmland, the CRP Menu Tool will help
                  you find the available CRP practices for enrollment. Various
                  conservation priorities can be addressed and used to filter
                  out the best practice matches that respond to different
                  environmental concerns. Currently, only agricultural land in
                  the states of Illinois, Mississippi and Missouri is supported.
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a
                  href="/PracticeList"
                  className="btn btn-primary big-maroon-btn"
                >
                  Explore Available Practices
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
}

export default Home;
