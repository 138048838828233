import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Sidebar from "./Components/Sidebar";
import Map from "./Components/Map";
import "./style.css";

function EligibilityCheck() {
    const [pinCenter, setPinCenter] = useState(null);
    const [addressCenter, setAddressCenter] = useState(null);
    const [fieldBoundary, setFieldBoundary] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [drawingMode, setDrawingMode] = useState(false);
    const [loading, setLoading] = useState(false);
    return(
        <>
            <Header/>
            <div className="eligibility-wrapper">
                {loading && <Spin className="spinner" tip="Loading" size="large"/>}
                <div className="sidebar-wrapper">
                    <Sidebar
                        pinCenter={pinCenter} 
                        setAddressCenter={setAddressCenter}
                        setFieldBoundary={setFieldBoundary}
                        selectedField={selectedField}
                        setSelectedField={setSelectedField}
                        setDrawingMode={setDrawingMode}
                        setLoading={setLoading}
                    />
                </div>
                <div className="map-wrapper">
                    <Map 
                        pinCenter={pinCenter}
                        setPinCenter={setPinCenter}
                        addressCenter={addressCenter}
                        fieldBoundary={fieldBoundary}
                        selectedField={selectedField}
                        setSelectedField={setSelectedField}
                        drawingMode={drawingMode}
                    />
                </div>
            </div>
            <Footer/>
        </>
    );
    }
    export default EligibilityCheck; 