import React from "react";
import { Col, Row, Form, Checkbox, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import aquatic from "../../../images/priority/Aquatic.png";
import biggame from "../../../images/priority/BigGame.png";
import carbonstorage from "../../../images/priority/CarbonStorage.png";
import drought from "../../../images/priority/Drought.png";
import watertemperature from "../../../images/priority/WaterTemperature.png";
import excesswind from "../../../images/priority/ExcessWind.png";
import flooding from "../../../images/priority/Flooding.png";
import forestbirds from "../../../images/priority/ForestBirds.png";
import grasslandbirds from "../../../images/priority/GrasslandBirds.png";
import pollinators from "../../../images/priority/Pollinators.png";
import snowdrift from "../../../images/priority/SnowDrift.png";
import soilhealth from "../../../images/priority/SoilHealth.png";
import watererosion from "../../../images/priority/WaterErosion.png";
import waterfowl from "../../../images/priority/Waterfowl.png";
import waterquality from "../../../images/priority/WaterQuality.png";
import winderosion from "../../../images/priority/WindErosion.png";
import "../style.css";

const CheckboxGroup = Checkbox.Group;
let CurrentIndex = 0;
function Step3({ selectedPriorities, selectedCheckboxes }) {
  const allPriorities = [
    {
      src: winderosion,
      alt: "winderosion",
      label: "Wind Erosion",
      value: "winderosion",
      qContent: "Wind erosion causes damage to land by transporting soil, which can cause soil loss, dryness, and soil deterioration.",
    },
    {
      src: watererosion,
      alt: "watererosion",
      label: "Water Erosion",
      value: "watererosion",
      qContent: "Erosion of soil by running water through fields or along stream banks.",
    },
    {
      src: soilhealth,
      alt: "soilhealth",
      label: "Soil Health",
      value: "soilhealth",
      qContent: "Water quality concerns may result from compaction, organic matter depletion, excess salts and chemicals nutrients, and/or aggregate instability.",
    },
    {
      src: excesswind,
      alt: "excesswind",
      label: "Excess Wind",
      value: "excesswind",
      qContent: "Wind can lead to problems with soil erosion, snowdrift, and inefficient energy use.",
    },
    {
      src: drought,
      alt: "drought",
      label: "Drought",
      value: "drought",
      qContent: "Lack of water can be caused by weather patterns, water depletion, and inefficient use of irrigation water.",
    },
    {
      src: watertemperature,
      alt: "watertemperature",
      label: "Water Temperature",
      value: "elevatedwatertemperature",
      qContent: "High surface water temperatures can cause problems for aquatic life and water quality.",
    },

    {
      src: flooding,
      alt: "flooding",
      label: "Flooding",
      value: "flooding",
      qContent: "Ponding, flooding, and other drainage issues caused by excess water in soil.",
    },
    {
      src: snowdrift,
      alt: "snowdrift",
      label: "Snow Drift",
      value: "snowdrift",
      qContent: "Drifted snow that can prohibit humans and animals from accessing structures or features.",
    },
    {
      src: waterquality,
      alt: "waterquality",
      label: "Water Quality",
      value: "waterquality",
      qContent:"Water quality concerns may result from excess nutrients, pathogens, chemicals, pesticides, and/or pollutants in water supply.",
    },

    {
      src: aquatic,
      alt: "aquatic",
      label: "Aquatic",
      value: "aquatic",
      qContent: "Fish and other aquatic wildlife.",
    },
    {
      src: biggame,
      alt: "biggame",
      label: "Big Game",
      value: "biggame",
      qContent: "Deer, elk, pigs, bears, and other wildlife hunted for sport.",
    },
    {
      src: grasslandbirds,
      alt: "grasslandbirds",
      label: "Grassland Birds",
      value: "grasslandbirds",
      qContent: "Birds, such as quail and pheasant, that require grassland cover.",
    },
    {
      src: forestbirds,
      alt: "forestbirds",
      label: "Forest Birds",
      value: "forestbirds",
      qContent: "Birds, such as turkey, that require forest cover.",
    },

    {
      src: pollinators,
      alt: "pollinators",
      label: "Pollinators",
      value: "pollinators",
      qContent: "Pollinators include honeybees, butterflies, birds, bats and other animals.",
    },
    {
      src: waterfowl,
      alt: "waterfowl",
      label: "Waterfowl",
      value: "waterfowl",
      qContent: "Ducks, geese, and swans that require water or wetland cover.",
    },
    {
      src: carbonstorage,
      alt: "carbonstorage",
      label: "Carbon Storage",
      value: "carbonstage",
      qContent: "Storage and sequestration of carbon dioxide from the atmosphere.",
    },
  ];

  const renderHeading = (index) => {
    let toReturn = null;
    switch (index) {
      case 0:
        toReturn = "Soil";
        break;
      case 4:
        toReturn = `Water`;
        break;
      case 9:
        toReturn = `Wildlife`;
        break;
      case 15:
        toReturn = `Co-benefits`;
        break;
      default:
        break;
    }
    return toReturn;
  };

  return (
    <>
      <Row>
        <Col span={24} className="mt-3">
          <div>
            <b>3) Which conservation priorities would you like to address the most?</b>
          </div>
        </Col>
      </Row>
      <Form>
        <Row gutter={40} className="mt-3 checkbox-list">
          <CheckboxGroup
            className="checkbox-group"
            onChange={selectedPriorities}
            defaultValue={selectedCheckboxes}
          >
            {allPriorities.map((priority, index) => {
              CurrentIndex = index;
              return (
                <>
                  <h1 className={renderHeading(CurrentIndex) ? "typeHeading" : ""}>
                    {renderHeading(CurrentIndex)}
                  </h1>
                  <Checkbox
                    className="checkbox-container"
                    value={priority.value}
                  >
                    <img
                      className="checkbox-image"
                      src={priority.src}
                      alt={priority.alt}
                    />
                    <Popover
                      overlayStyle={{
                        maxWidth: "20vw",
                      }}
                      placement="topRight"
                      content={priority.qContent}
                      trigger="hover"
                    >
                      <span className="pa">
                        <QuestionCircleOutlined />
                      </span>
                    </Popover>
                    <div className="checkbox-label">{priority.label}</div>
                  </Checkbox>
                </>
              );
            })}
          </CheckboxGroup>
        </Row>
      </Form>
    </>
  );
}
export default Step3;
