import React from "react";
import { Image, Col, Row } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import leann from '../../images/team/Hopper_5x7.jpg';
import kristine from '../../images/team/Evans_5x7.jpg';
import mark from '../../images/team/McConnell_5x7.jpg';
import sathish from '../../images/team/Samiappan_5x7.jpg';
import amanda from '../../images/team/Sesser_5x7.jpg';
import shannon from '../../images/team/Westlake_5x7.jpg';
import ethan from '../../images/team/Liang_5x7.jpg';
import reshma from '../../images/team/Mandli_5x7.jpg';
import daniel from '../../images/team/Egerson_5x7.jpg';
import rishita from '../../images/team/Garg_5x7.jpg';
import dixie from '../../images/team/Cartwright_5x7.jpg';
import tracey from '../../images/team/DuBard_5x7.jpg';
import './style.css';

function ProjectTeam(){
    return(
      <>
        <Header />
          <Row >
            <Col span={16} offset={2} >
              <h3 className="mt-3">Project team</h3>
              <p  className="mt-3"><b>Leann Hopper,</b> Project Coordinator</p>
              <p>
                Leann Hopper is the Research Program Manager of the CRP Menu project. She has extensive experience working with government agencies in agriculture and forestry conservation. Her research interests include agricultural conservation, agricultural economics and agribusiness.
                <br/><br/>
                <i>Email:</i> lh1761@msstate.edu
              </p>
            </Col>
            <Col span={4} className="mt-3">
                    <Image  src={leann} alt="leann" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>  
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Kristine Evans,</b> Project Investigator</p> 
              <p className="mt-3">
                Kristine Evans is a Project Investigator of the CRP Menu project. She is the Associate Director of the Geosystems Research Institute and an Associate Professor with the Department of Wildlife, Fisheries, and Aquaculture. Her research interests include conservation design, avian ecology, geospatial applications, landscape conservation, strategic habitat conservation, agricultural conservation, and biological monitoring.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:kristine.evans@msstate.edu">kristine.evans@msstate.edu</a>
              </p>
            </Col>
            <Col span={4}>
              <Image  src={kristine} alt="kristine" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p  className="mt-3"><b>Mark McConnell,</b> Project Investigator</p> 
              <p  className="mt-3">
                Mark McConnell is a Project Investigator of the CRP Menu project. He is an Assistant Professor of Upland Birds in the Department of Wildlife, Fisheries, and Aquaculture at Mississippi State University. His research interest includes conservation in working landscapes, gamebird ecology, and precision agriculture.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:mdm380@msstate.edu">mdm380@msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={mark} alt="mark" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p  className="mt-3"><b>Sathish Samiappan,</b> Project Investigator</p> 
              <p  className="mt-3">
                Sathish Samiappan is a Project Investigator of the CRP Menu project. He is an Associate Research Professor in the Geosystems Research Institute at Mississippi State University. His research interests include computer vision, machine learning, pattern recognition, signal and image processing, invasive species, and data driven land conservation.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:sathish@gri.msstate.edu">sathish@gri.msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={sathish} alt="sathish" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Amanda Sesser,</b> Project Investigator</p> 
              <p className="mt-3">
                Amanda Sesser is a Project Investigator of the CRP Menu project. She is the Southeast Conservation Adaptation Strategy (SECAS) partnership coordinator and specializes in involving stakeholders, decision-makers, and scientists in processes to solve climate-change challenges and large-scale conservation planning and design, as well as scientific translation and communication to policymakers.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:amanda_sesser@fws.gov">amanda_sesser@fws.gov</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={amanda} alt="amanda" rootClassName="mt-3 teamimg" />
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Shannon Westlake,</b> Project Investigator</p> 
              <p>
                Shannon Westlake is a Project Investigator of the CRP Menu project. She is a Regional Social Scientist for the U.S. Fish and Wildlife Service. Her research interests include conservation social science research and application, private lands conservation, co-production, and pollinator conservation.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:shannon_westlake@fws.gov">shannon_westlake@fws.gov</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={shannon} alt="westlake" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Yong (Ethan) Liang,</b> Developer</p> 
              <p className="mt-3">
                Yong (Ethan) Liang is part of the CRP Menu project development team. He is a GIS Developer at the Geosystems Research Institute. He has extensive experience within the field of GIS, including spatial and statistical analysis, data processing, model building, web-based mapping and development, SQL/No-SQL database management, remote sensing, and cartography.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:eliang@gri.msstate.edu">eliang@gri.msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={ethan} alt="ethan" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Reshma Devi Mandli,</b> Developer</p> 
              <p className="mt-3">
                Reshma Devi Mandli is part of the CRP Menu project development team. She is a Full Stack Web Developer with experience in Frontend and backend development and has a strong understanding of web development concepts, programming languages, and frameworks. She takes a comprehensive approach to web development, handling all aspects from the user interface to managing databases and deploying the application. She has worked with various technologies and tools commonly used in the industry.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:reshma@gri.msstate.edu">reshma@gri.msstate.edu</a>
              </p>
            </Col>
            <Col span={4}>
              <Image  src={reshma} alt="reshma" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          {/* <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Anthony Collini,</b> Web Developer</p> 
              <p className="mt-3">
                Anthony Collini is part of the project development team. He is a Front-end Web Developer who specializes in turning complex concepts into clean, efficient, and responsive code. He has in-depth knowledge of the React ecosystem and leverages components, state management, and modern development practices to create efficient and scalable applications.
                <br/><br/>
                <i>Email:</i> collini.anthony@gmail.com
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={blank} alt="anthony" className="mt-3"/>
            </Col>
          </Row> */}
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Daniel Egerson,</b> Graduate Student </p> 
              <p className="mt-3">
                Daniel Egerson is a Social Science Researcher for the CRP Menu project. He is a Graduate Research Assistant with the Department of Wildlife, Fisheries, and Aquaculture at Mississippi State University. His background is in Sociology and Population Study. He has experience engaging social science frameworks, methodologies, and qualitative and quantitative analysis in explaining the dynamics of human behavior. His interest is in humans and the environment, Urban and Rural Sociology, and special populations including migrant farmers and farming households.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:de631@msstate.edu">de631@msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={daniel} alt="daniel" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Rishita Garg,</b> Graduate Student</p> 
              <p className="mt-3">
                Rishita Garg is a Computational Engineering graduate student at Mississippi State University. She is part of the CRP Menu project development team. Her background is in GIS and remote sensing and has experience in geospatial modeling, spatial analysis, image interpretation and visualization, mapping, data munging and cleaning, and qualitative/quantitative analysis.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:rg1408@msstate.edu">rg1408@msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
                <Image  src={rishita} alt="rishita" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Dixie Cartwright,</b> Outreach Coordinator</p> 
              <p className="mt-3">
                Dixie Cartwright is a Training and External Facilitator with the Geosystems Research Institute. She specializes in designing and managing outreach initiatives, developing professional educational programs, and enhancing program and institute awareness through marketing and promotion efforts.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:dixie@gri.msstate.edu">dixie@gri.msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={dixie} alt="dixie" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
          <Row>
            <Col span={16} offset={2}>
              <p className="mt-3"><b>Tracey DuBard,</b> Outreach Coordinator</p> 
              <p className="mt-3">
                Tracey DuBard is an Outreach Coordinator with the Geosystems Research Institute. She engages in coordinating and supporting informal training and outreach efforts. In addition, she is a doctoral candidate in Educational Leadership with an emphasis in Higher Education Leadership.
                <br/><br/>
                <i>Email:</i> <a className="email-address" href="mailto:tdubard@gri.msstate.edu">tdubard@gri.msstate.edu</a>
              </p>
            </Col> 
            <Col span={4}>
              <Image  src={tracey} alt="tracey" rootClassName="mt-3 teamimg"/>
            </Col>
          </Row>
        <Footer />
      </>
    );
}
export default ProjectTeam;