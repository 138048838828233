import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Button, Card } from "antd";
import { LiaSearchLocationSolid, LiaDrawPolygonSolid } from "react-icons/lia";
import {
  MdOutlineEditLocation,
  MdOutlineMyLocation,
  MdPinDrop,
  MdOutlineSatellite,
  MdOutlineUploadFile
} from "react-icons/md";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import axios from "axios";
import hostURL from '../../../Common/constants';
import { ModuleContext } from "../../../context/Context";
import '../style.css';

const Sidebar = ({
  pinCenter,
  setAddressCenter,
  setFieldBoundary,
  selectedField,
  setSelectedField,
  setDrawingMode,
  setLoading
}) => {
  const navigate = useNavigate();
  const [inputMode, setInputMode] = useState(null);
  const [addressValue, setAddressValue] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressObjects, setAddressObjects] = useState([]);
  const {featureList, updateFieldDetails, practicePreference} = useContext(ModuleContext);
  
  const backToPracticeList = () => {
    navigate("/PracticeList");
  };

  const handleBack = () => {
    setInputMode(null);
    setFieldBoundary(null);
    setDrawingMode(false);
  };

  const getOptions = async (address) => {
    const response = await axios.get(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      address +
      ".json?proximity=ip&access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
    );
    if (response.data) {
      setAddressObjects(response.data);
      const options = response.data.features.map((feature) => {
        return {key: feature.id, value: feature.place_name}
      });
      return options;
    };
  };

  const onAddressChange = async (value) => {
    setAddressValue(value);
    if (value) {
      const options = await getOptions(value);
      setAddressOptions(options);
    };
  };
  
  const onAddressSelect = (value) => {
    const addressFeature = addressObjects.features.filter((feature) => feature.place_name === value)[0];
    setAddressCenter(addressFeature.center);
  };

  const loadFieldBoundary = async () => {
    // For development on local server
    try {
      const response = await axios.post(hostURL+'api/field/boundary');
      const fieldBoundaryFeatures = response.data.fieldBoundary.rows.map((feature) => {
        const geometry = JSON.parse(feature.geometry);
        return { type: "Feature", geometry: geometry };
      });
      const fieldBoundaryData = { type: "FeatureCollection", features: fieldBoundaryFeatures };
      setFieldBoundary(fieldBoundaryData);
    } catch (error) {
      console.log(error.response.data);
    };
  };

  const submitDraw = async () => {
    if (featureList.length === 0) {
      // setAlerttext("At least one polygon is required.");
    } else {
      // setAlerttext(false);
      const newList = featureList;
      const data = {
        type: "MultiPolygon",
        coordinates: newList.map((feature) => feature.geometry.coordinates),
      };

      // For development on local server
      try {
        setLoading(true);
        const response = await axios.post(hostURL+'api/field/details', { data });
        updateFieldDetails(response.data);
        setDrawingMode(false);
        navigate("/FieldReport");
        setLoading(false);
      } catch (error) {
        console.log(error.response.data);
      };
    };
  };

  return (
    <div className="sidebar">
      {practicePreference ? (
        <h3>
          You have previously selected
          <br/>
          <b style={{color: "maroon"}}>{practicePreference.county} County, </b>
          <b style={{color: "maroon"}}>{practicePreference.state}</b>
        </h3>
      ) : (
        <h3>
          You haven't selected any state or county
        </h3>
      )}
      <div className="button-container">
        <Button className="rectangular-button">
          <MdOutlineEditLocation />
          <b> Edit Location</b>
        </Button>
        <Button className="rectangular-button">
          <MdOutlineMyLocation />
          <b> Use Your Current Location</b>
        </Button>
      </div>
      {!inputMode && (
        <>
          <h4>Would you like to pick your field of interest by:</h4>
          <div>
            <Button
              className="square-button"
              onClick={() => setInputMode("pin")}
            >
              <MdPinDrop size={"6vh"}/>
              <br/>
              <b>Drop Your Pin</b>
            </Button>
            <Button
              className="square-button"
              onClick={() => setInputMode("address")}
            >
              <LiaSearchLocationSolid size={"6vh"}/>
              <br/>
              <b>Type Your Address</b>
            </Button>
            <Button
              className="square-button"
              onClick={() => {
                setInputMode("field");
                loadFieldBoundary();
              }}
            >
              <MdOutlineSatellite size={"6vh"}/>
              <br/>
              <b>Pick From Fields</b>
            </Button>
            <Button
              className="square-button"
              onClick={() => {
                setInputMode("draw");
                setDrawingMode(true);
              }}
            >
              <LiaDrawPolygonSolid size={"6vh"}/>
              <br/>
              <b>Draw Your</b>
              <br/>
              <b>Field Boundary</b>
            </Button>
            <Button
              className="square-button"
              onClick={() => setInputMode("upload")}
            >
              <MdOutlineUploadFile size={"6vh"}/>
              <br/>
              <b>Upload Your</b>
              <br/>
              <b>Field Boundary</b>
            </Button>
          </div>
          <div>
            <Button className="redirect-button" onClick={backToPracticeList}>
              <GrLinkPrevious />
              <b> To Explore CRP Practices</b>
            </Button>
          </div>
        </>
      )}
      {!!inputMode && (
        <>
          {inputMode === "pin" && (
            <div className="pin-instruction">
              <p>Please drop a pin on the map to locate a certain field</p>
              <div className="button-container">
                <Button className="back-button" onClick={handleBack}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button">
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}
          {inputMode === "address" && (
            <div className="address-instruction">
              <p>Please type your address here:</p>
              <AutoComplete
                value={addressValue}
                options={addressOptions}
                onChange={onAddressChange}
                onSelect={onAddressSelect}
                placeholder="Address of your field"
                allowClear={true}
                style={{width: "25vw"}}
              />
              <br/>
              <div className="button-container">
                <Button className="back-button" onClick={handleBack}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button">
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}
          {inputMode === "field" && (
            <div className="field-instruction">
              <p>Please pick your field from the pregenerated field boundaries:</p>
              <div className="button-container">
                <Button className="back-button" onClick={handleBack}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button">
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}
          {inputMode === "draw" && (
            <div className="draw-instruction">
              <p>Please draw the boundary of your field on the map</p>
              <div className="button-container">
                <Button className="back-button" onClick={handleBack}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button" onClick={submitDraw}>
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}
          {inputMode === "upload" && (
            <div className="upload-instruction">
              <p>Please drag and drop the zipped shapefile for your field boundary here:</p>
              <div className="button-container">
                <Button className="back-button" onClick={handleBack}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button">
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Sidebar;