import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import './style.css';

function Contact() {

  return (
    <>
      <Header />
      <iframe
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=sNtR7YavokWcl3P7OTXfF7zHI1custlCm_5fZ6uZdcRUNVhONlZYMjhaWjdJRzdXTklZUjg1M1NIVy4u&embed=true"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        style={{border: "none", maxWidth: "100%", maxHeight: "100vh"}}
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscreen
      ></iframe>
      <Footer />
    </>
  );
}
export default Contact;
