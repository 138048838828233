import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip, Popover, Row, Col, Collapse } from "antd";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import parse from "html-react-parser";
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import {
  TbHexagonLetterC,
  TbHexagonLetterP,
  TbHexagonLetterR,
  TbHexagonLetterS,
  TbSquareLetterC,
  TbSquareLetterG
} from "react-icons/tb";

//step2 Images
import aquaculture from "../../images/landscape/AquaculturePond.png";
import cropland from "../../images/landscape/Cropland.png";
import timber from "../../images/landscape/Timber.png";
import pasture from "../../images/landscape/Pasture.png";
import wetland from "../../images/landscape/Wetland.png";

//step3 Images
import aquatic from "../../images/priority/Aquatic.png";
import biggame from "../../images/priority/BigGame.png";
import carbonstorage from "../../images/priority/CarbonStorage.png";
import drought from "../../images/priority/Drought.png";
import watertemperature from "../../images/priority/WaterTemperature.png";
import excesswind from "../../images/priority/ExcessWind.png";
import flooding from "../../images/priority/Flooding.png";
import forestbirds from "../../images/priority/ForestBirds.png";
import grasslandbirds from "../../images/priority/GrasslandBirds.png";
import pollinators from "../../images/priority/Pollinators.png";
import snowdrift from "../../images/priority/SnowDrift.png";
import soilhealth from "../../images/priority/SoilHealth.png";
import watererosion from "../../images/priority/WaterErosion.png";
import waterfowl from "../../images/priority/Waterfowl.png";
import waterquality from "../../images/priority/WaterQuality.png";
import winderosion from "../../images/priority/WindErosion.png";

import "./style.css";

const Practice = ({ practiceNumber }) => {
  const Panel = Collapse.Panel;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPracticeData, setSelectedPracticeData] = useState(false);
  const [selectedTopPracticeList, setselectedTopPracticeList] = useState(null);
  debugger;
  const fipsId = parseInt(window.location.pathname.split("/")[2].trim());
  const prTitle = atob(window.location.pathname.split("/")[4]);
  const prCode = prTitle.split("-")[0].trim();
  const prName = atob(window.location.pathname.split("/")[4])
    .split(/-(.*)/)[1]
    .trim();
  const pdfLink = atob(window.location.pathname.split("/")[3]);

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland"
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetch(
        `${hostURL}api/natpractices/${prCode}/${encodeURIComponent(prName)}`
      )
        .then((response) => response.json())
        .then((json) => {
          debugger;
          setSelectedPracticeData(json);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    })();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Header />

      {selectedPracticeData ? (
        <div className="practice-page">
          <h3><u>{prTitle}</u></h3>
          {selectedPracticeData.descriptn ? (
        <>
          <h4>Description:</h4>
          <p>{selectedPracticeData.descriptn}</p>
        </>
      ) : null}
          {/* <p className="lead">
            Contract Period: {selectedPracticeData.cntrctpd} Years
          </p> */}

          <h4>Related Priorities:</h4>
          {/* <ul style={{ columnCount: 2 }}>
            {/* {selectedPracticeData.crep ? (
              <li>Conservation Reserve Enhancement Program (CREP)</li>
            ) : (
              <></>
            )} 
            {selectedPracticeData.erosion ? <li>Soil Erosion</li> : <></>}
            {selectedPracticeData.soilhealth ? <li>Soil Health</li> : <></>}
            {selectedPracticeData.waterquality ? <li>Water Quality</li> : <></>}
            {selectedPracticeData.wildlife ? <li>Wildlife</li> : <></>}
            {selectedPracticeData.carbon ? <li>Carbon</li> : <></>}
            {selectedPracticeData.protection ? <li>Protection</li> : <></>}
            {selectedPracticeData.pollination ? <li>Pollination</li> : <></>}
            {selectedPracticeData.floodcontrol ? <li>Flood Control</li> : <></>}
            {selectedPracticeData.wetland ? <li>Wetland</li> : <></>}
            {selectedPracticeData.recreation ? <li>Recreation</li> : <></>}
          </ul> */}

          {selectedPracticeData && (
            <div>
            <p className="step3_images">
              <Tooltip placement="topLeft" title={getLabels.pasture}>
                {selectedPracticeData.pasture && (
                  <img className="img_selected_cb" src={pasture} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.aquaculture}>
                {selectedPracticeData.aquaculture && (
                  <img className="img_selected_cb" src={aquaculture} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.timber}>
                {selectedPracticeData.timber && (
                  <img className="img_selected_cb" src={timber} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.cropland}>
                {selectedPracticeData.cropland && (
                  <img className="img_selected_cb" src={cropland} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.wetland}>
                {selectedPracticeData.wetland && (
                  <img className="img_selected_cb" src={wetland} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.aquatic}>
                {selectedPracticeData.aquatic && (
                  <img className="img_selected_cb" src={aquatic} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.biggame}>
                {selectedPracticeData.biggame && (
                  <img className="img_selected_cb" src={biggame} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.carbonstorage}>
                {selectedPracticeData.carbonstage && (
                  <img className="img_selected_cb" src={carbonstorage} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.drought}>
                {selectedPracticeData.drought && (
                  <img className="img_selected_cb" src={drought} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.watertemperature}>
                {selectedPracticeData.watertemperature && (
                  <img className="img_selected_cb" src={watertemperature} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.excesswind}>
                {selectedPracticeData.excesswind && (
                  <img className="img_selected_cb" src={excesswind} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.flooding}>
                {selectedPracticeData.flooding && (
                  <img className="img_selected_cb" src={flooding} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.forestbirds}>
                {selectedPracticeData.forestbirds && (
                  <img className="img_selected_cb" src={forestbirds} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.grasslandbirds}>
                {selectedPracticeData.grasslandbirds && (
                  <img className="img_selected_cb" src={grasslandbirds} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.pollinators}>
                {selectedPracticeData.pollinators && (
                  <img className="img_selected_cb" src={pollinators} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.snowdrift}>
                {selectedPracticeData.snowdrift && (
                  <img className="img_selected_cb" src={snowdrift} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.soilhealth}>
                {selectedPracticeData.soilhealth && (
                  <img className="img_selected_cb" src={soilhealth} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.watererosion}>
                {selectedPracticeData.watererosion && (
                  <img className="img_selected_cb" src={watererosion} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.waterfowl}>
                {selectedPracticeData.waterfowl && (
                  <img className="img_selected_cb" src={waterfowl} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.waterquality}>
                {selectedPracticeData.waterquality && (
                  <img className="img_selected_cb" src={waterquality} />
                )}
              </Tooltip>
              <Tooltip placement="topLeft" title={getLabels.winderosion}>
                {selectedPracticeData.winderosion && (
                  <img className="img_selected_cb" src={winderosion} />
                )}
              </Tooltip>
            </p>
            </div>
            
          )}
          {/* <p className="signup-icons">
            <h4>Signups: </h4>
            {selectedPracticeData.general && (
              <Tooltip title="Available In General Signup">
                <TbSquareLetterG size="2em" color="maroon" />
              </Tooltip>
            )}
            {selectedPracticeData.continous && (
              <Tooltip title="Available In Continuous Signup">
                <TbSquareLetterC size="2em" color="maroon" />
              </Tooltip>
            )}
          </p>
          <p className="incentive-icons">
            <h4>Incentives: </h4>
            {selectedPracticeData["32_5_p_sip"] && (
              <Tooltip title="Signup Incentive">
                <TbHexagonLetterS size="2em" color="maroon" />
                <b className="incentive-percent">32.5%</b>
              </Tooltip>
            )}
            {selectedPracticeData["20_p_rr_incentive"] && (
              <Tooltip title="Rental Rate Incentive">
                <TbHexagonLetterR size="2em" color="maroon" />
                <b className="incentive-percent">20%</b>
              </Tooltip>
            )}
            {selectedPracticeData["50_p_pip"] && (
              <Tooltip title="Practice Incentive">
                <TbHexagonLetterP size="2em" color="maroon" />
                <b className="incentive-percent">50%</b>
              </Tooltip>
            )}
            {selectedPracticeData["climate_smrt_incentive"] !==
              ("false" || "NA") && (
              <Tooltip title="Climate Smart Incentive">
                <TbHexagonLetterC size="2em" color="maroon" />
                <b className="incentive-percent">
                  {selectedPracticeData["climate_smrt_incentive"] + "%"}
                </b>
              </Tooltip>
            )}
          </p> */}
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold"></p>
            </Col>
            <Col span={3}>
              <p className="text-bold">General signup</p>
            </Col>
            <Col span={3}>
              <p className="text-bold">Continuous signup</p>
            </Col>
            <Col span={3}>
              <p className="text-bold">Continuous through HELI</p>
            </Col>
            <Col span={3}>
              <p className="text-bold">
                Continuous through Wellhead Protection
              </p>
            </Col>
            <Col span={3}>
              <p className="text-bold">
                Continuous through Farmable Wetland Program
              </p>
            </Col>
            <Col span={3}>
              <p className="text-bold">Continuous through SAFE</p>
            </Col>
            <Col span={3}>
              <p className="text-bold">Continuous through CLEAR30</p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">Contract Period</p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctpd !== "false"
                  ? selectedPracticeData.cntrctpd + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthrucontinous !== "false"
                  ? selectedPracticeData.cntrctprdthrucontinous + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthruheli !== "false"
                  ? selectedPracticeData.cntrctprdthruheli + " Years"
                  : "Not Eligble"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthruwellhead !== "false"
                  ? selectedPracticeData.cntrctprdthruwellhead + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthrufwp !== "false"
                  ? selectedPracticeData.cntrctprdthrufwp + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthrusafe !== "false"
                  ? selectedPracticeData.cntrctprdthrusafe + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.cntrctprdthruclear30 !== "false"
                  ? selectedPracticeData.cntrctprdthruclear30 + " Years"
                  : "Not Eligible"}
              </p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">32.5% Signup Incentive Payment (SIP)</p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData["32_5_p_sip"] ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>

                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthrucontinous ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthruheli ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthruwellhead ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthrufwp ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthrusafe ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.sipthruclear30 ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">50% Practice Incentive Payment (PIP)</p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData["50_p_pip"] ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthrucontinous ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthruheli ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthruwellhead ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthrufwp ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthrusafe ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.pipthruclear30 ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">Climate - Smart Incentive</p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climate_smrt_incentive ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climatesmartincentivethrucontinous ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climatesmartincentivethruheli ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>

                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>{selectedPracticeData.climatesmartincentivethruwellhead ? (
                <>
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                </>

              ) : (
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climatesmartincentivethrufwp ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climatesmartincentivethrusafe ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>

                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.climatesmartincentivethruclear30 ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">Rental Rate Incentive</p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.rentalrateincentivethrucontinuous ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.rentalrateincentivesafe ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
          </Row>
          <Row className="col-table-data">
            <Col span={3}>
              <p className="text-bold">Rental Rate Adjustment</p>
            </Col>
            <Col span={3}>
              <>
                <Tooltip placement="topLeft" title="Not Eligible">
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              </>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <>
                <Tooltip placement="topLeft" title="Not Eligible">
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              </>
            </Col>
            <Col span={3}>
              <>
                <Tooltip placement="topLeft" title="Not Eligible">
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              </>
            </Col>
            <Col span={3}><p>
              {selectedPracticeData.rentalrateadjthrufwp ? (
                <>
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              )}
            </p>
            </Col>
            <Col span={3}>
              <p>
                <>
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              </p>
            </Col>
            <Col span={3}>
              <p>
                {selectedPracticeData.rentalrateadjthruclear30 ? (
                  <>
                    <Tooltip placement="topLeft" title="Eligible">
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip placement="topLeft" title="Not Eligible">
                      <CloseCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <div className="cost-maint-manage">
            <Collapse header="" accordion className="mt-3 crp-basics-paragraph">
              {selectedPracticeData.costshareavble && (
                <Panel header="Cost share available for" key="1">
                  {parse(selectedPracticeData.costshareavble)}
                </Panel>
              )}

              {/* {selectedPracticeData.costsharenotavble && (
                <Panel header="Cost share not available for" key="2">
                  {parse(selectedPracticeData.costsharenotavble)}
                </Panel>
              )} */}
              {selectedPracticeData.maintenance && (
                <Panel header="Maintenance" key="3">
                  {parse(selectedPracticeData.maintenance)}
                </Panel>
              )}
              {selectedPracticeData.maintenanceforclear30 && (
                <Panel header="Maintenance for Clear30" key="4">
                  {parse(selectedPracticeData.maintenanceforclear30)}
                </Panel>
              )}
              {selectedPracticeData.management && (
                <Panel header="Management" key="5">
                  {parse(selectedPracticeData.management)}
                </Panel>
              )}
              {selectedPracticeData.eligibilityforheli && (
                <Panel header="Eligibility for Heli" key="6">
                  {parse(selectedPracticeData.eligibilityforheli)}
                </Panel>
              )}
              {selectedPracticeData.eligiblityforwellhead && (
                <Panel header="Eligibility for Wellhead Protection" key="7">
                  {parse(selectedPracticeData.eligiblityforwellhead)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythrucontinuous && (
                <Panel header="Eligibility through Continuous" key="8">
                  {parse(selectedPracticeData.eligiblitythrucontinuous)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythruclear30 && (
                <Panel header="Eligibility through Clear30" key="9">
                  {parse(selectedPracticeData.eligiblitythruclear30)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythrufwp && (
                <Panel header="Eligibility through FWP" key="10">
                  {parse(selectedPracticeData.eligiblitythrufwp)}
                </Panel>
              )}
            </Collapse>
            <br />
          </div>
          {/* <hr className="my-4" />
          
          {/* <h3>Available for Enrollment:</h3> */}
          {/* <p><b>Available for General CRP Signup (competitive): </b>{selectedPracticeData.general?'Yes':'No'}</p>
          <p><b>Available for Continuous CRP Signup (non-competitive): </b>{selectedPracticeData.continous?'Yes':'No'}</p>
          
          <h4>Additional available incentives with signup:
          <Popover
            overlayStyle={{
              maxWidth: "10vw",
            }}
            placement="top"
            content={<h6><i>Additional incentives vary by conservation practice and are typically a one-time payment based on a percentage of the first annual rental payment for your enrolled field at the initial time of contract enrollment and are distributed following practice installation and eligible costs are verified. Any cost-share payments, if available, for maintenance and management are distributed following verification of eligible costs during the appropriate contract period.</i></h6>}
            trigger="hover"
          >
            <span className="pa">
            <InfoCircleOutlined />
            </span>
          
          </Popover>
          </h4> 
          <ul style={{ columnCount: 2 }}>
            <li>
              Signup Incentive Payment (SIP) (32.5%):{" "}
              {selectedPracticeData["32_5_p_sip"] ? "Eligible" : "Not Eligible"}
            </li>
            <li>
              Practice Incentive Payment (PIP) (50%):{" "}
              {selectedPracticeData["50_p_pip"] ? "Eligible" : "Not Eligible"}
            </li>
            <li>
              Rental Rate Incentive (20%):{" "}
              {selectedPracticeData["20_p_rr_incentive"]
                ? "Eligible"
                : "Not Eligible"}
            </li>
            <li>
              Climate Smart Incentive (%):{" "}
              {isNaN(selectedPracticeData.climate_smrt_incentive)
                ? "Not Eligible"
                : selectedPracticeData.climate_smrt_incentive + "%"}
            </li>
          </ul>
          {/* {selectedPracticeData.notes.length > 2 ? (
            <>
              <h4>Notes:</h4>
              <p>{selectedPracticeData.notes}</p>
            </>
          ) : (
            ""
          )} */}
              {pdfLink !='false' && <div className="embed-responsive">
          <hr className="my-4" />
          {/* <Button type="primary"  onClick={showModal}>Factsheet</Button> */}

          <h4>
            <u>{prTitle}</u>
          </h4>
      
            <iframe
              className="embed-responsive-item"
              src={`/Practices/${pdfLink}`}
              allowFullScreen
            />
          </div>}
        </div>
      ) : (
        <></>
      )}
      {/* <Modal title={prTitle} open={isModalOpen} footer={null} destroyOnClose={true} onCancel={handleCancel}   width="80%">
        <iframe
          className="embed-responsive-item"
          src={`/Practices/${pdfLink}`}
          allowFullScreen
        />
      </Modal> */}
      <Footer />
    </>
  );
};

export default Practice;
