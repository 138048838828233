import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Collapse, Select } from "antd";
import Papa from 'papaparse';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import FilterMap from "../FilterMap";
import './style.css';

const PracticeReport = ({setPracticeNumber}) => {
  const [practiceList, setPracticeList] = useState([]);
  const [items, setItems] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [state, setState] = useState([]);
  const [county, setCounty] = useState([]);
  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    async function getPracticeData() {
      const response = await fetch('/PracticeList.csv');
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result.value);
      const results = Papa.parse(csv, { header: true });
      setPracticeList(results.data);
    };
    async function setCountyData() {
      const response = await fetch('/FIPS.csv');
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result.value);
      const results = Papa.parse(csv, { header: true });
      setCountyList(results.data);
    };
    getPracticeData();
    setCountyData();
  }, []);

  useEffect(() => {
    if (practiceList.length) {
      const practiceDetails = 
        practiceList.filter((practice) => practice.NUMBER && practice.NAME && practice.DESCRIPTION)
        .map((practice) => {
          return ({
            key: practice.NUMBER,
            label: practice.NUMBER + " " + practice.NAME,
            children: (
              <>
                <p>
                  {practice.DESCRIPTION}
                </p>
                <p>
                  <a
                    href="/Practice"
                    className="btn btn-primary small-maroon-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(practice.NUMBER);
                      setPracticeNumber(practice.NUMBER);
                      navigate("/Practice");
                    }}
                  >
                    Details
                  </a>
                </p>
              </>
            )
          })
        });
      setItems(practiceDetails);
    }
  }, [practiceList])

  const print = () => {
    window.print();
  };

  return (
    <>
      <Header />
      <div className="report">
        <h2 className="report-header">CRP Practice Availability Report</h2>
        <Button className="report-print-button" onClick={print}>Print</Button>
        <hr className="report-hr"/>
        <h3 className="report-section-header">Your Basic Information:</h3>
        <div className="report-basic-information">
          <p>
            <b>State:</b>
            Mississippi
          </p>
          <p>
            <b>County:</b>
            Oktibbeha
          </p>
          <p>
            <b>CRP Priorities:</b>
            ...
          </p>
        </div>
        <h3 className="report-section-header">Your Available Practices:</h3>
        <div className="report-practice-list">
          <Collapse items={items} />
        </div>
        <h3 className="report-section-header">Your Local USDA Office:</h3>
        <div className="report-usda-information">
          <p>Please reach out to your local USDA office for further information.</p>
          <p>
            Your Local USDA Office Information:
          </p>
          <p>
            <b>Starkville Service Center</b>
            Rural Development
            (662) 320-4009
          </p>
          <p>
            <b>Physical Address:</b>
            510 Highway 25 North
            Starkville, MS 39759
            Get Directions 
          </p>
          <p>
            <b>Mailing Address:</b>
            Mailing Address not available
          </p>
          <p>
            <b>Contact:</b>
            Clifton Russel
            clifton.russel@usda.gov
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PracticeReport;