import React from "react";
import { Col, Row } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import './style.css';
function About () {
    return (
      <>
      <Header />
          <Row span={24}>
            <Col>
      <div className="mybg1">

          <h1 align="center">This is About Page</h1>
          
            <h2><a href="/">Back</a></h2>

      </div>
      </Col>
      </Row>
      <Footer />

</>
    );
  }
  
  export default About;