import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Map, { Source, Layer } from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import { Button } from "antd";
import bbox from '@turf/bbox';
import { LuMap, LuSatellite } from "react-icons/lu";
import { ModuleContext } from "../../../context/Context";
import DrawControl from '../Helpers/DrawControl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "mapbox-gl/dist/mapbox-gl.css";
import '../style.css';

function FieldMap({
  pinCenter, 
  setPinCenter,
  addressCenter,
  fieldBoundary,
  selectedField,
  setSelectedField,
  drawingMode
}) {
  const mapRef = useRef();
  const [baseLayer, setBaseLayer] = useState("mapbox://styles/mapbox/light-v11");
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);
  const [countyFips, setCountyFips] = useState("00000");
  const {featureList, updateFeatureList, practicePreference} = useContext(ModuleContext);
  const stateFilter = ["match", ["get", "STATE"], ["IL", "MO", "MS"], true, false];
  const countyFilter = useMemo(() => ["==", "FIPS", countyFips], [countyFips]);

  const onClick = (e) => {
    if (fieldBoundary) {
      console.log(e);
      // setSelectedField(e.features[0]);
    };
  };

  const onUpdate = useCallback((e) => {
    const newList = [...featureList, e.features[0]];
    updateFeatureList(newList);
  }, []);

  const onDelete = useCallback((e) => {
    const newList = featureList.filter((feature) => feature.id !== e.features[0].id);
    updateFeatureList(newList);
  }, []);

  const getCursor = ({isHovering, isDragging}) => {
    return isDragging ? "grabbing" : isHovering ? "pointer" : "default";
  };

  useEffect(() => {
    if (practicePreference) {
      setCountyFips(practicePreference.fips);
    };
  }, [practicePreference]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.fitBounds(
        [
          [-95.6, 30.3],
          [-89.1, 41.3]
        ],
        {padding: 40, duration: 1000}
      );
      setTimeout(() => {
        var features = mapRef.current.querySourceFeatures("map-source", {
          sourceLayer: "c_08mr23-1pp4eg",
          filter: ["==", "FIPS", countyFips]
        });
        if (features.length) {
          // calculate the bounding box of the feature
          const [minLng, minLat, maxLng, maxLat] = bbox(features[0]);
    
          mapRef.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat]
            ],
            {padding: 40, duration: 1000}
          );
        };
      }, "1000");
    };
  }, [countyFips]);
  
  useEffect(() => {
    if (mapRef.current && fieldBoundary) {
      setInteractiveLayerIds(["field-boundary"]);
      const [minLng, minLat, maxLng, maxLat] = bbox(fieldBoundary);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {padding: 40, duration: 1000}
      );
    };
  }, [fieldBoundary]);
  
  return(
		<Map
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
      initialViewState={{
        longitude: -91,
        latitude: 36,
        zoom: 4.5
      }}
      style={{width: "70vw", height: "80vh"}}
      mapStyle={baseLayer}
      interactiveLayerIds={interactiveLayerIds}
      onClick={onClick}
      getCursor={getCursor}
    >
      {drawingMode && (
        <DrawControl
          displayControlsDefault={false}
          controls={{
            polygon: "true",
            trash: "true",
          }}
          defaultMode="simple_select"
          onCreate={onUpdate}light-v11
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
      <Button className="layer-button" onClick={() => setBaseLayer("mapbox://styles/mapbox/light-v11")}>
        <LuMap />
      </Button>
      <Button className="layer-button" onClick={() => setBaseLayer("mapbox://styles/mapbox/satellite-streets-v12")}>
        <LuSatellite />
      </Button>
      {!fieldBoundary && (
        <Source
          id="map-source"
          type="vector"
          url="mapbox://chuck0520.5pq9omti"
          maxzoom={22}
          minzoom={0}
        >
          <Layer
            id="county-boundary"
            source-layer="c_08mr23-1pp4eg"
            type="line"
            paint={{
              "line-color": "#800000",
              "line-width": 1,
              "line-opacity": 0.4
            }}
            minzoom={0}
            maxzoom={22}
            filter={stateFilter}
          />
          <Layer
            id="county-highlight"
            source-layer="c_08mr23-1pp4eg"
            type="line"
            paint={{
              "line-color": "#800000",
              "line-width": 3,
              "line-opacity": 1
            }}
            minzoom={0}
            maxzoom={22}
            filter={countyFilter}
          />
        </Source>
      )}
      {!!fieldBoundary && (
        <Source id="field-source" type="geojson" data={fieldBoundary}>
          <Layer
            id="field-boundary"
            type="fill"
            paint={{
              "fill-outline-color": "#800000",
              "fill-color": "#800000",
              "fill-opacity": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                1,
                0.2
              ],
            }}
          />
        </Source>
      )}
    </Map>
	);
}
export default FieldMap;