import React from 'react';
import Routes from '../routes';
import { ModuleContextProvider } from '../context/Context';
import '../App.css';

function App() {
  return (
      <>
        <ModuleContextProvider>
          <Routes />
        </ModuleContextProvider>
      </>
  );
}

export default App;