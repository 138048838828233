import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { Tree, Row, Col } from "antd";
import releaseNotesImg from './releaseNotes.png';
import { DownOutlined } from "@ant-design/icons";
import "./style.css";

const ReleaseNotes = () => {
  const [selectedReleaseVer, setSelectedReleaseVer] = useState(null);

  const treeData = [
    {
      title: "Version 1.0",
      key: null,
      children: [
        {
          title: "1.0.0",
          key: "1-0-0"
        },
        {
          title: "1.0.1",
          key: "1-0-1"
        },
        {
          title: "1.0.2",
          key: "1-0-2"
        },
      ]
    },
    // {
    //   title: "Version 2.0",
    //   key: null
    // }
  ];
  
  const releaseNotesData = {
    '1-0-0': `
      <p><b>1.0.0</b></p>
      <p><u>Apr 01, 2024</u></p>
      <p>This is the soft release of the CRP Menu Tool.</p>
      <p>Updated Features:</p>
      <ul>
        <li>CRP Basics</li>
        <li>Explore Practices</li>
        <li>Contact Us</li>
      </ul>
    `,
    '1-0-1': `
      <p><b>1.0.1</b></p>
      <p><u>Aug 08, 2024</u></p>
      <p>This is a minor update release.</p>
      <p>Updated Features:</p>
      <ul>
        <li>CRP Basics</li>
        <li>Team</li>
      </ul>
    `,
    '1-0-2': `
      <p><b>1.0.2</b></p>
      <p><u>Aug 14, 2024</u></p>
      <p>This is a minor update release.</p>
      <p>Updated Features:</p>
      <ul>
        <li>CRP Basics</li>
        <li>Practice Details</li>
      </ul>
    `,
  };

  const onSelect = (selectedKeys, info) => {
    if (selectedKeys[0])
      setSelectedReleaseVer(releaseNotesData[selectedKeys[0]])
    console.log("selected", selectedKeys, info);
  };

  return (
    <>
      <Row>
        <Col offset={2} span={20} className="mt-3">
          <h3 className="mt-3"><center>CRP Menu Tool - Release notes</center></h3>
          <p className="mt-3">
            We're thrilled to announce the soft release of this new CRP Menu Tool!
            Please see below for the milestones and released features:
          </p>
        </Col>
      </Row>
      <Row>
        <Col offset={2} span={4}>
          <h3 className="releaseTreeTitle">Versions:</h3>
          <Tree
            className="releaseTree"
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandedKeys={["0-0-0"]}
            onSelect={onSelect}
            treeData={treeData}
          />
        </Col>
        <Col span={18}>
          {selectedReleaseVer && <div className="releaseNotesDesc"> {parse(selectedReleaseVer)}</div>}
          {/* {!selectedReleaseVer && <img src={releaseNotesImg} className="releaseNotesImg" />} */}
        </Col>
      </Row>
    </>
  );
};

export default ReleaseNotes;
