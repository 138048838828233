import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Spin, Tooltip } from "antd";
import { FaAngleDoubleRight } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import {
  TbHexagonLetterC,
  TbHexagonLetterP,
  TbHexagonLetterR,
  TbHexagonLetterS,
  TbSquareLetterC,
  TbSquareLetterG,
} from "react-icons/tb";
import { ModuleContext } from "../../context/Context";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WizardForm from "../ExplorebyGoals/wizardForm";
import hostURL from "../../Common/constants";

// Images List

//step2 Images
import aquaculture from "../../images/landscape/AquaculturePond.png";
import cropland from "../../images/landscape/Cropland.png";
import timber from "../../images/landscape/Timber.png";
import pasture from "../../images/landscape/Pasture.png";
import wetland from "../../images/landscape/Wetland.png";

//step3 Images
import aquatic from "../../images/priority/Aquatic.png";
import biggame from "../../images/priority/BigGame.png";
import carbonstorage from "../../images/priority/CarbonStorage.png";
import drought from "../../images/priority/Drought.png";
import watertemperature from "../../images/priority/WaterTemperature.png";
import excesswind from "../../images/priority/ExcessWind.png";
import flooding from "../../images/priority/Flooding.png";
import forestbirds from "../../images/priority/ForestBirds.png";
import grasslandbirds from "../../images/priority/GrasslandBirds.png";
import pollinators from "../../images/priority/Pollinators.png";
import snowdrift from "../../images/priority/SnowDrift.png";
import soilhealth from "../../images/priority/SoilHealth.png";
import watererosion from "../../images/priority/WaterErosion.png";
import waterfowl from "../../images/priority/Waterfowl.png";
import waterquality from "../../images/priority/WaterQuality.png";
import winderosion from "../../images/priority/WindErosion.png";

// Images List

import myCommonApiCall from "../../Common/apicalls";
import "./style.css";

const PracticeList = ({ setPracticeNumber }) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCounty, setselectedCounty] = useState(null);
  const [countyListData, setCountyListData] = useState(null);
  const [step2Priorities, setStep2Priorities] = useState([]);
  const [step3Priorities, setStep3Priorities] = useState([]);
  const [clickedState, setClickedState] = useState(null);
  const [clickedCounty, setClickedCounty] = useState(null);
  const [clickedFips, setClickedFips] = useState(null);
  const [selectedFips, setSelectedFips] = useState(null);
  const [selectedPracticeList, setselectedPracticeList] = useState(null);
  const [selectedTopPracticeList, setselectedTopPracticeList] = useState(null);
  const [selectedPracticeData, setSelectedPracticeData] = useState(null);
  const [filteredPractices, setFilteredPractices] = useState(null);
  const [filteredPracticesData, setFilteredPracticesData] = useState([]);
  const [filteredPracticesPriorities, setFilteredPracticesPriorities] =
    useState([]);
  const [filteredPracticesNumber, setFilteredPracticesNumber] = useState(0);
  const [step2CheckedTypes, setstep2CheckedTypes] = useState([]);
  const [step3CheckedTypes, setstep3CheckedTypes] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [rankingScore, setRankingScore] = useState([]);

  const [notselected, setNotselected] = useState(false);
  // const {
  //   updatePracticePreference,
  //   priorityPreference,
  //   updatePriorityPreference
  // } = useContext(ModuleContext);
  const prev = () => {
    setWizardStep(wizardStep - 1);
  };

  const next = () => {
    if (selectedState != null && selectedCounty != null) {
      setNotselected(false);
      setWizardStep(wizardStep + 1);
    } else {
      setNotselected(true);
    }
  };

  const landCoverTypesArray = [
    "aquaculture",
    "cropland",
    "timber",
    "pasture",
    "wetland",
  ];

  const navigate = useNavigate();

  const tagColors = [
    "#640000",
    "#731815",
    "#822b25",
    "#913c36",
    "#a04d47",
    "#ae5e58",
    "#bc6f6a",
    "#ca807c",
    "#d7928f",
    "#e5a3a2",
    "#f2b6b5",
    "#ffc8c8",
  ];

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
  };

  const step2Images = [
    "aquaculture",
    "cropland",
    "timber",
    "pasture",
    "wetland",
  ];

  const step3Images = [
    "aquatic",
    "biggame",
    "carbonstage",
    "drought",
    "watertemperature",
    "excesswind",
    "flooding",
    "forestbirds",
    "grasslandbirds",
    "pollinators",
    "snowdrift",
    "soilhealth",
    "watererosion",
    "waterfowl",
    "waterquality",
    "winderosion",
  ];

  const authToken = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    fetch(hostURL + "api/states", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const statesListData = json.map((item) => {
          return { value: item.scode, label: item.sname };
        });
        setStateList(statesListData);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (filteredPractices) {
      (async () => {
        setLoading(true);
        let metaData = [];
        let priorityData = [];

        await Promise.all(
          filteredPractices.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/natpractices/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  metaData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );

        await Promise.all(
          filteredPractices.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/crppriorities/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  priorityData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );

        setFilteredPracticesData(metaData);
        setFilteredPracticesPriorities(priorityData);
        setLoading(false);
      })();
    }
  }, [filteredPractices]);

  useEffect(() => {
    // updatePracticePreference({
    //   state: selectedState,
    //   county: selectedCounty,
    //   fips: selectedFips,
    //   practices: filteredPractices,
    //   practiceList: selectedPracticeList
    // });
  }, [
    selectedState,
    selectedCounty,
    filteredPractices,
    selectedPracticeList,
    step2CheckedTypes,
    step3CheckedTypes,
    step2Priorities,
    step3Priorities,
  ]);

  const onStateChange = (selectedState) => {
    setLoading(true);
    setSelectedState(selectedState);
    setselectedCounty(null);
    setFilteredPractices(null);
    fetch(hostURL + "api/counties?state=" + selectedState)
      .then((response) => response.json())
      .then((json) => {
        const countiesListData = json.map((item) => {
          return { value: item.cname, label: item.cname };
        });
        setCountyList(countiesListData);
        setCountyListData(json);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  // const storeSearchData = (values, sroute)=>{

  //   const formData = {
  //     userId: localStorage.getItem('userRef'),
  //     route: window.location.href,
  //     subRoute: sroute,
  //     formData:JSON.stringify(values)
  //   };

  //   setLoading(true);
  //   fetch(hostURL+"api/usersearchdata", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization:  authToken,
  //     },
  //     body: JSON.stringify(formData)
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setLoading(false);
  //     })
  //     .catch((error) => console.error(error));
  // };

  const onCountyChange = (selectedCounty) => {
    setLoading(true);
    setFilteredPractices(null);
    setselectedCounty(selectedCounty);
    let queryState =
      clickedState && clickedState !== selectedState
        ? clickedState
        : selectedState;
    fetch(
      `${hostURL}api/${queryState.toLowerCase()}countypractices/${queryState}/${selectedCounty}`
    )
      .then((response) => response.json())
      .then((json) => {
        const respData = json[0];
        setselectedPracticeList(json);
        setSelectedFips(respData.fips);
        let toShowData =
          respData &&
          JSON.parse(
            respData.assetdata.substring(1, respData.assetdata.length - 1)
          );
        setFilteredPractices(toShowData);

        if (authToken)
          myCommonApiCall.storeSearchData(
            { queryState, selectedCounty },
            "step#" + wizardStep
          );
        setLoading(false);
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };

  const onPrioritiesSelect = (selectedPriorities) => {
    // Wizard steps start from 0
    if (wizardStep === 1) {
      setstep2CheckedTypes(selectedPriorities);
    } else if (wizardStep === 2) {
      setstep3CheckedTypes(selectedPriorities);
    }
  };

  const handleFilter = () => {
    setFilterOpen(true);
  };

  const handleOk = () => {
    setFilterOpen(false);
  };

  const handleCancel = () => {
    setFilterOpen(false);
  };

  const getPracticeReport = () => {
    navigate("/PracticeReport");
  };

  useEffect(() => {
    if (clickedFips !== selectedFips) {
      setSelectedState(clickedState);
      setselectedCounty(clickedCounty);
      setSelectedFips(clickedFips);
      if (clickedState !== selectedState) {
        onStateChange(clickedState);
        onCountyChange(clickedCounty);
      } else {
        onCountyChange(clickedCounty);
      }
    }
  }, [clickedFips]);

  useEffect(() => {
    if (countyListData) {
      let countyDataArray = countyListData.filter(
        (item) => item.cname === selectedCounty
      );
      let assetData =
        countyDataArray.length > 0 ? countyDataArray[0].assetdata : null;
      let topPracticeList = assetData
        ? assetData.replace('"', "").split(",")
        : null;
      setselectedTopPracticeList(topPracticeList);
    }
  }, [countyListData, selectedCounty]);

  useEffect(() => {
    let allCheckedTypes = step2CheckedTypes.concat(step3CheckedTypes);
    setSelectedCheckboxes(allCheckedTypes);
  }, [step2CheckedTypes, step3CheckedTypes]);

  useEffect(() => {
    if (
      filteredPractices &&
      filteredPracticesPriorities.length > 0 &&
      rankingScore.length > 0
    ) {
      let filteredPracticesScores = filteredPracticesPriorities.map((data) => {
        let totalScoreArray = rankingScore.map((item) => {
          let score = data[item.priority] ? item.score : 0;
          return score;
        });
        let totalScore = totalScoreArray.reduce((a, b) => {
          return a + b;
        }, 0);
        return {
          pnumber: data.pnumber,
          score: totalScore,
        };
      });

      let scoredFilteredPractices = filteredPractices.map((practice) => {
        let scoreArray = filteredPracticesScores.filter(
          (item) => item.pnumber === practice.title.split("-")[0].trim()
        );
        let score = scoreArray.length > 0 ? scoreArray[0].score : 0;
        return {
          title: practice.title,
          pdflink: practice.pdflink,
          notes: practice.notes,
          score: score,
        };
      });

      let sortedFilteredPractices = scoredFilteredPractices.sort(
        (a, b) => b.score - a.score
      );
      setFilteredPractices(sortedFilteredPractices);
    }
  }, [rankingScore]);

  return (
    <>
      <Header />
      <Spin spinning={loading}>
        <Modal
          centered={true}
          className="filter-modal"
          open={filterOpen}
          width="80vw"
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          footer={[
            wizardStep > 0 ? (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Back
              </Button>
            ) : (
              ""
            ),
            wizardStep < 3 ? (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => next()}
              >
                Next
              </Button>
            ) : (
              ""
            ),
            wizardStep === 3 ? (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => setFilterOpen(false)}
              >
                Confirm
              </Button>
            ) : (
              ""
            ),
          ]}
        >
          <WizardForm
            className="filter-wizard-form"
            wizardStep={wizardStep}
            setWizardStep={setWizardStep}
            stateList={stateList}
            selectedState={selectedState}
            stateChange={onStateChange}
            countyList={countyList}
            selectedCounty={selectedCounty}
            countyChange={onCountyChange}
            setClickedState={setClickedState}
            setClickedCounty={setClickedCounty}
            setClickedFips={setClickedFips}
            selectedFips={selectedFips}
            selectedPriorities={onPrioritiesSelect}
            selectedCheckboxes={selectedCheckboxes}
            setFilterOpen={setFilterOpen}
            setRankingScore={setRankingScore}
            notselected={notselected}
          />
        </Modal>
        <div className="practice-list">
          <div className="filter-container">
            <Button className="filter-button" onClick={handleFilter}>
              <b>
                <IoFilter />
              </b>
              <b>Filters</b>
            </Button>
            <div className="filter-results">
              <h3>
                Available Practices:{" "}
                {document.getElementsByClassName("practice-card").length}
              </h3>
              <h3>
                {step2CheckedTypes.length ? "Selected Land Cover Types: " : null}
                {step2CheckedTypes.map((type) => <b className="filter-tag">{getLabels[type]}</b>)}
              </h3>
              <h3>
                {step3CheckedTypes.length ? "Selected Priorities: " : null}
                {step3CheckedTypes.map((type) => <b className="filter-tag">{getLabels[type]}</b>)}
              </h3>
            </div>
          </div>
          <Row className="filter-practice-row" span={20}>
            {filteredPractices &&
              filteredPractices.length > 0 &&
              filteredPractices.map((practice, index) => {
                let currentDataArray = filteredPracticesData.filter(
                  (data) => data.pnumber === practice.title.split("-")[0].trim()
                );
                let currentData =
                  currentDataArray.length > 0 ? currentDataArray[0] : null;
                let currentPrioritiesArray = filteredPracticesPriorities.filter(
                  (data) => data.pnumber === practice.title.split("-")[0].trim()
                );
                let currentPriorities =
                  currentPrioritiesArray.length > 0
                    ? currentPrioritiesArray[0]
                    : null;
                let currentPrioritiesList = currentPriorities
                  ? Object.keys(currentPriorities).filter(
                      (key) => currentPriorities[key] === true
                    )
                  : [];
                let landCoverIntersection =
                  currentPrioritiesList.length > 0
                    ? currentPrioritiesList.filter((landcover) =>
                        step2CheckedTypes.includes(landcover)
                      )
                    : [];
                let priorityIntersection =
                  currentPrioritiesList.length > 0
                    ? currentPrioritiesList.filter((priority) =>
                        step3CheckedTypes.includes(priority)
                      )
                    : [];

                let showPracticeCard =
                  step2CheckedTypes.length === 0 &&
                  step3CheckedTypes.length === 0
                    ? true
                    : step2CheckedTypes.length === 0
                    ? priorityIntersection.length > 0
                      ? true
                      : false
                    : step3CheckedTypes.length === 0
                    ? landCoverIntersection.length > 0
                      ? true
                      : false
                    : landCoverIntersection.length > 0 &&
                      priorityIntersection.length > 0
                    ? true
                    : false;

                return (
                  showPracticeCard && (
                    <Col span={5} offset={1}>
                      <Card
                        className="practice-card"
                        title={
                          <>
                            <p>
                              {practice.title.split("-")[0]}{" "}
                              <div style={{ float: "right" }}>
                                {!!practice.score && practice.score >= 5 && (
                                  <span className="practice-best-match">
                                    Best Match
                                  </span>
                                )}
                                {!!practice.score &&
                                  practice.score >= 3 &&
                                  practice.score < 5 && (
                                    <span className="practice-good-match">
                                      Good Match
                                    </span>
                                  )}
                                {!!practice.score &&
                                  practice.score >= 1 &&
                                  practice.score < 3 && (
                                    <span className="practice-fair-match">
                                      Fair Match
                                    </span>
                                  )}
                              </div>
                            </p>
                            <p className="practice-title">
                              {practice.title.split("-")[1]}
                            </p>
                          </>
                        }
                        headStyle={{
                          backgroundColor: "#5D1725",
                          color: "white",
                        }}
                      >
                        {selectedTopPracticeList &&
                          selectedTopPracticeList.includes(
                            practice.title.split("-")[0].trim()
                          ) && <div class="most-common">Most Common</div>}

                        {currentPriorities && (
                          <p className="step2_images">
                            <b>Land Cover:</b>
                            <br />
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.pasture}
                            >
                              {currentPriorities.pasture && (
                                <img
                                  className="img_selected_cb"
                                  src={pasture}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.aquaculture}
                            >
                              {currentPriorities.aquaculture && (
                                <img
                                  className="img_selected_cb"
                                  src={aquaculture}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.timber}
                            >
                              {currentPriorities.timber && (
                                <img className="img_selected_cb" src={timber} />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.cropland}
                            >
                              {currentPriorities.cropland && (
                                <img
                                  className="img_selected_cb"
                                  src={cropland}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.wetland}
                            >
                              {currentPriorities.wetland && (
                                <img
                                  className="img_selected_cb"
                                  src={wetland}
                                />
                              )}
                            </Tooltip>
                          </p>
                        )}

                        {currentPriorities && (
                          <p className="step3_images">
                            <b>Priorities:</b>
                            <br />
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.aquatic}
                            >
                              {currentPriorities.aquatic && (
                                <img
                                  className="img_selected_cb"
                                  src={aquatic}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.biggame}
                            >
                              {currentPriorities.biggame && (
                                <img
                                  className="img_selected_cb"
                                  src={biggame}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.carbonstorage}
                            >
                              {currentPriorities.carbonstage && (
                                <img
                                  className="img_selected_cb"
                                  src={carbonstorage}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.drought}
                            >
                              {currentPriorities.drought && (
                                <img
                                  className="img_selected_cb"
                                  src={drought}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.watertemperature}
                            >
                              {currentPriorities.watertemperature && (
                                <img
                                  className="img_selected_cb"
                                  src={watertemperature}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.excesswind}
                            >
                              {currentPriorities.excesswind && (
                                <img
                                  className="img_selected_cb"
                                  src={excesswind}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.flooding}
                            >
                              {currentPriorities.flooding && (
                                <img
                                  className="img_selected_cb"
                                  src={flooding}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.forestbirds}
                            >
                              {currentPriorities.forestbirds && (
                                <img
                                  className="img_selected_cb"
                                  src={forestbirds}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.grasslandbirds}
                            >
                              {currentPriorities.grasslandbirds && (
                                <img
                                  className="img_selected_cb"
                                  src={grasslandbirds}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.pollinators}
                            >
                              {currentPriorities.pollinators && (
                                <img
                                  className="img_selected_cb"
                                  src={pollinators}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.snowdrift}
                            >
                              {currentPriorities.snowdrift && (
                                <img
                                  className="img_selected_cb"
                                  src={snowdrift}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.soilhealth}
                            >
                              {currentPriorities.soilhealth && (
                                <img
                                  className="img_selected_cb"
                                  src={soilhealth}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.watererosion}
                            >
                              {currentPriorities.watererosion && (
                                <img
                                  className="img_selected_cb"
                                  src={watererosion}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.waterfowl}
                            >
                              {currentPriorities.waterfowl && (
                                <img
                                  className="img_selected_cb"
                                  src={waterfowl}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.waterquality}
                            >
                              {currentPriorities.waterquality && (
                                <img
                                  className="img_selected_cb"
                                  src={waterquality}
                                />
                              )}
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={getLabels.winderosion}
                            >
                              {currentPriorities.winderosion && (
                                <img
                                  className="img_selected_cb"
                                  src={winderosion}
                                />
                              )}
                            </Tooltip>
                          </p>
                        )}

                        {currentData && (
                          <p className="signup-icons">
                            <b>Signups: </b>
                            <br />
                            {currentData.general && (
                              <Tooltip title="Available In General Signup">
                                <TbSquareLetterG size="2em" color="maroon" />
                              </Tooltip>
                            )}
                            {currentData.continous && (
                              <Tooltip title="Available In Continuous Signup">
                                <TbSquareLetterC size="2em" color="maroon" />
                              </Tooltip>
                            )}
                          </p>
                        )}

                        {currentData && (
                          <p className="incentive-icons">
                            <b>Incentives: </b>
                            <br />
                            {currentData["32_5_p_sip"] && (
                              <Tooltip title="Signup Incentive">
                                <TbHexagonLetterS size="2em" color="maroon" />
                                <b className="incentive-percent">32.5%</b>
                              </Tooltip>
                            )}
                            {currentData["20_p_rr_incentive"] && (
                              <Tooltip title="Rental Rate Incentive">
                                <TbHexagonLetterR size="2em" color="maroon" />
                                <b className="incentive-percent">20%</b>
                              </Tooltip>
                            )}
                            {currentData["50_p_pip"] && (
                              <Tooltip title="Practice Incentive">
                                <TbHexagonLetterP size="2em" color="maroon" />
                                <b className="incentive-percent">50%</b>
                              </Tooltip>
                            )}
                            {currentData["climate_smrt_incentive"] !==
                              ("false" || "NA") && (
                              <Tooltip title="Climate Smart Incentive">
                                <TbHexagonLetterC size="2em" color="maroon" />
                                <b className="incentive-percent">
                                  {currentData["climate_smrt_incentive"] + "%"}
                                </b>
                              </Tooltip>
                            )}
                          </p>
                        )}

                        <p>
                          <a
                            href={`/Practice/${
                              selectedPracticeList.fips
                            }/${btoa(practice.pdflink)}/${btoa(
                              practice.title
                            )}`}
                            target="_blank"
                            className="detail-button"
                          >
                            <b>Details</b> <FaAngleDoubleRight />
                          </a>
                        </p>
                      </Card>
                    </Col>
                  )
                );
              })}
          </Row>
        </div>
        <Footer />
      </Spin>
    </>
  );
};
export default PracticeList;
