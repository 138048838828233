import React, { useState, useEffect } from "react";
import { Col, Row, Collapse, Button, Modal, Input } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import hostURL from "../../Common/constants";
import parse from "html-react-parser";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Fieldbanner from "../../images/basics/fieldbuffer_BST_banner.png";
import "./style.css";

const CRPBasics = () => {
  const Panel = Collapse.Panel;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToOpen, setFileToOpen] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [practicesListData, setPracticesListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Search } = Input;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetch(
        `${hostURL}api/natpractices`
      )
        .then((response) => response.json())
        .then((json) => {
          json=  json.sort((a, b) => (parseInt(a.cpsequence) > parseInt(b.cpsequence) ? 1 : -1));
          setPracticesListData(json);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    })();
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (filename) => {
    // debugger;
    setFileToOpen(filename);
    setIsModalOpen(true);
  };

  // Function to filter panels based on search query
  const filterPanels = (panelContent) => {
    if (!searchQuery) return panelContent;

    const regex = new RegExp(searchQuery, "gi");
    return panelContent.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    );
  };

  const setColorForSearchData = (element, color) => {
    element.style.backgroundColor = color;
  };

  const searchTheAccordionContent = (mySearchKey) => {
    var value = mySearchKey.toLowerCase();

    var elems = document.getElementsByClassName("ant-collapse-header-text");
    for (var i = 0; i < elems.length; ++i) {
      if (value.length > 0 && elems[i].innerHTML.toLowerCase().includes(value))
        setColorForSearchData(elems[i], "yellow");
      else setColorForSearchData(elems[i], "transparent");
    }
  };


  const getPracticesDataHTML= (practiceList)=>{
    let toReturn = ''
    var toExcludePractices = ['CP4B', 'CP21B', 'CP21S' , 'CP22B' ,'CP22S'];
    practiceList && practiceList.map((item, i) => {
        if(!(toExcludePractices.includes(item.pnumber)))
        {
        toReturn+= `<div>
        <a
          href="/Practice/undefined/${btoa(
            (item.pdflink).replace(/['"]+/g, '')
          )}/${btoa(item.pnumber+' - '+item.pname)}"
          target="_blank"
          className=""
        >
        <h4><u>${item.pnumber} ${item.pname}</u></h4></a>
        <p>${item.descriptn}</p></div>`;
                  }
   })
  return toReturn;
  }

  return (
    <>
      <Header />

      <Row>
        <Col span={20} offset={2}>
          <h2 className="mt-3">About the Conservation Reserve Program (CRP)</h2>
          <br />
          <p className="crp-basics-paragraph">
            Here are some basic information and commonly asked questions related to the Conservation Reserve Program (CRP). Please cilck any of the questions or search the keyword below to find your answers.
          </p>
          <Search
            id="searchInput"
            placeholder="Search"
            onChange={(e) => {
              searchTheAccordionContent(e.target.value);
            }}
             //style={{ width: 400 }}
          />
          <div className="crpBasicsData">
            <div className="crp-basics-group">
              <h3 className="crp-basics-group-title">General CRP Information</h3>
              <Collapse
                header="General CRP Information"
                accordion
                defaultActiveKey={["1"]}
                className="mt-3 crp-basics-paragraph"
              >
                <Panel
                  header="What is the Conservation Reserve Program (CRP)?"
                  key="1"
                >
                  <p>
                    The Conservation Reserve Program (CRP) is a federally funded
                    voluntary program that contracts with agricultural producers
                    so environmentally sensitive agricultural land is not farmed
                    or ranched, but instead devoted to conservation benefits.
                    CRP participants establish long-term, resource-conserving
                    plant species, such as approved grasses or trees (known as
                    “covers”) to control soil erosion, improve water quality,
                    and develop wildlife habitat. In return, USDA’s Farm Service
                    Agency (FSA) provides participants with rental payments and
                    cost share assistance during the life of the contract.
                    Contract duration is typically between 10 and 15 years. The
                    CRP is authorized by the Food Security Act of 1985 and was
                    reauthorized by the Agricultural Improvement Act of 2018
                    (the 2018 Farm Bill). FSA administers CRP, while other USDA
                    agencies, including the USDA Natural Resources Conservation
                    Service, and partners provide technical support.
                  </p>
                </Panel>
                <Panel header="What are the available practices?" key="2">
                  {/* <h3>List of available Practices</h3> */}
                {practicesListData && parse(getPracticesDataHTML(practicesListData))}          
                </Panel>
                <Panel header="Who is eligible for CRP?" key="3">
                  <div>
                    <p>
                      A producer must have owned or operated the land for at
                      least 12 months prior to submitting the offer for
                      continuous or 12 months before the close of general or
                      grasslands signup, unless:{" "}
                    </p>
                    <ul>
                      <li>
                        The new owner acquired the land due to the previous
                        owner’s death;
                      </li>
                      <li>
                        The ownership change occurred due to foreclosure where
                        the owner exercised a timely right of redemption in
                        accordance with state law or;
                      </li>
                      <li>
                        The circumstances of the acquisition present adequate
                        assurance to FSA that the new owner did not acquire the
                        land for the purpose of placing it in CRP.
                      </li>
                      <li>
                        The owner/operator is an eligible participant in the
                        Transition Incentives Program for beginning, socially
                        disadvantaged, or veteran farmer or ranger.
                      </li>
                    </ul>
                    <p>
                      Under the 2018 Farm Bill, landowners, and operators with
                      an Adjusted Gross Income (AGI) {">"}$900,000 in the 3
                      taxable years prior to contract start are prohibited from
                      enrolling in most FSA and NRCS programs, including CRP.
                      CRP contracts require annual verification that enrollees
                      are in compliance with the AGI limit.
                    </p>
                  </div>
                </Panel>
                <Panel header="What land is eligible to enroll?" key="4">
                  <div>
                    <p>
                      For cropland, land must be planted or considered planted
                      to an agricultural commodity for four of six crop years
                      from 2012 to 2017, and that is physically and legally
                      capable of being planted (no planting restrictions due to
                      an easement or other legally binding instrument) in a
                      normal manner to an agricultural commodity.
                    </p>
                    <p>
                      For general signup, land also must meet one of the
                      following criteria:{" "}
                    </p>
                    <ul>
                      <li>
                        Have a weighted average erosion index of 8 or higher;{" "}
                      </li>
                      <li>
                        Be enrolled in a CRP contract that expires Sept. 30 or;{" "}
                      </li>
                      <li>
                        Be located in a national or state CRP conservation
                        priority area.
                      </li>
                    </ul>
                    <p>
                      Marginal pasture land that is located immediately next and
                      parallel to a stream, wetland, or other water body may
                      also be eligible for continuous signup.
                    </p>
                  </div>
                </Panel>
                <Panel
                  header="How much CRP can be enrolled in my county?"
                  key="5"
                >
                  <p>
                    Under most circumstances, CRP acreage cannot exceed 25% of
                    the total cropland acreage in a given county. This cap can
                    be waived under some practices and circumstances.
                  </p>
                </Panel>
                <Panel
                  header="What is the difference between continuous and general signup?"
                  key="6"
                >
                  <div>
                    <p>
                      Under <b>General CRP Signup</b>, landowners can offer
                      eligible land for CRP general enrollment annually during
                      announced enrollment periods. Offers for CRP contracts are
                      ranked according to the Environmental Benefits Index
                      (EBI). FSA uses the following EBI factors to assess the
                      environmental benefits for the land offered: 1) wildlife
                      habitat benefits, 2) water quality, 3) reduced erosion, 4)
                      enduring benefits, 5) air quality, and 6) cost.
                    </p>
                    <p>
                      Under <b>Continuous CRP Signup</b>, environmentally
                      sensitive land devoted to certain conservation practices
                      can be enrolled in CRP at any time. Offers are
                      automatically accepted provided the land and producer meet
                      certain eligibility requirements and the enrollment levels
                      do not exceed the statutory cap. Unlike CRP enrollments
                      under General CRP signups or Grassland CRP, offers for
                      Continuous CRP are not subject to competitive bidding.
                    </p>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => showModal("Continuous Enrollment.pdf")}
                    >
                      <FilePdfOutlined />
                      Continuous Enrollment
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="primary"
                      onClick={() => showModal("General Signup (2023).pdf")}
                    >
                      <FilePdfOutlined />
                      General Signup (2023)
                    </Button>
                  </div>
                </Panel>
                <Panel header="How long is a CRP contract?" key="7">
                  <p>
                    A CRP contract is typically 10 to 15 years in length,
                    depending on the practice. Practices enrolled as part of the
                    CLEAR30 Initiative are 30 years in length. CRP contracts
                    typically go into effect on October 1st of the year that you
                    signed the contract.
                  </p>
                </Panel>
                <Panel
                  header="How can I contact USDA to sign up for CRP or learn more?"
                  key="8"
                >
                  <p>
                    Landowners and producers who are interested in learning more
                    or applying for CRP will first need to contact their county
                    USDA Service Center office, which can be found here:&nbsp;
                    <a href="https://www.farmers.gov">www.farmers.gov</a> or&nbsp;
                    <a href="https://offices.usda.gov">offices.usda.gov</a>.
                  </p>
                </Panel>
              </Collapse>
            </div>

            <div className="crp-basics-group">
              <h3 className="crp-basics-group-title">Practice Details</h3>
              <Collapse className="mt-3 crp-basics-paragraph">
                <Panel header="What are Conservation Practices?" key="1">
                  <p>
                    A conservation practice is an agreed to measure of
                    conservation action, with associated maintenance and
                    management requirements that the landowner commits to as
                    part of their CRP Conservation Plan. Practices come with a
                    specific set of “standards” and “specifications”. The
                    acronym “CP” before a practice number represents the
                    Conservation Practice number (e.g., CP42 Pollinator
                    Habitat).
                  </p>
                </Panel>
                <Panel header="What is an Annual Rental Payment?" key="2">
                  <p>
                    An annual rental payment is made to landowners participating
                    and compliant in a CRP contract on their property. These
                    payments compensate the landowner for removing land from
                    production. Incentive payments are generally included in
                    annual rental payments, with the exception of Practice
                    Incentive Payments (PIP), payments for cost-shared
                    activities, and forest management incentives.
                  </p>
                </Panel>
                <Panel header="What is a Soil Rental Rate?" key="3">
                  <p>
                    A soil rental rate is what is used to calculate your annual
                    rental payment. Soil rental rates are derived for each
                    county and are calculated based on how productive the soils
                    are within the county (called “relative productivity”). This
                    rate is estimated using a combination of data from the USDA
                    Natural Resources Conservation Service (NRCS) and estimates
                    of average local cash rental or cash-rent equivalent from
                    the USDA Farm Services Agency (FSA).
                  </p>
                  <p>
                    Soil rental rate estimates are a maximum allowable rental
                    rate. Maximum soil rental rates are calculated based on the
                    weighted average of the three predominant soil types in the
                    area that is offered as a CRP contract.
                  </p>
                  <p>
                    Under the 2018 Farm Bill, CRP enrollments under general CRP
                    sign-up are limited to 85% of the average county soil rental
                    rate. Landowners/producers may offer reduce soil rental
                    rates in bids for General Sign-up CRP to increase
                    competitiveness. CRP enrollments under the continuous CRP
                    sign-up are limited to 90% of the average county soil rental
                    rate.
                  </p>
                  <p>
                    Note - soil rental rates for marginal pasturelands are
                    calculated based on “prevailing rental values of marginal
                    pasturelands in riparian areas.” Grassland rental rates are
                    estimated based on 75% of the “estimated grazing value of
                    the land” (7 CFR Part 1410).
                  </p>
                </Panel>
                <Panel
                  header="What is a Practice Incentive Payment (PIP) & Sign-up Incentive Payment (SIP)?"
                  key="4"
                >
                  <p>
                    Depending on the practice, additional incentive payments may
                    be offered alongside the CRP contract.
                  </p>
                  <p>
                    Practice Incentive Payments, or PIPs, offer an additional
                    percentage to offset some of the eligible installation costs
                    under eligible continuous signup practices. PIPs are
                    one-time payments that are issued after the practice is
                    installed and verification of the eligible costs is
                    complete. PIPs cannot be allocated as partial payments.
                  </p>
                  <p>
                    Signup Incentive Payments, or SIPs, are available in some
                    practices at the time of initial contract enrollment for
                    eligible continuous signup practices. SIPs provide up to
                    32.5% of the amount of first annual rental payment for
                    eligible contracts and are a one-time payment that occurs
                    following practice installation.
                  </p>
                  <p>
                    SIPs and PIPsare not available for re-enrollments of land
                    that was previously enrolled in CRP.
                  </p>
                </Panel>
                <Panel header="What is a cost-share payment?" key="5">
                  <p>
                    If your conservation plan requires out-of-pocket costs for
                    establishment, maintenance, and/or management of your lands
                    enrolled in a CRP contract, you may be eligible for certain
                    cost-share payments. These are payments that reimburse
                    landowners for eligible activities up to a certain percent.
                    Cost-share payments vary by practice.
                  </p>
                </Panel>
                <Panel
                  header="What is the difference between maintenance and management requirements?"
                  key="6"
                >
                  <h4>Management</h4>
                  <p>Management is a conservation activity that is part of the agreed-upon CRP contract to be conducted after the establishment of the CRP Practice on a field. Management is conducted to upkeep or enhance the quality of the practice on the property and requirements and their timing and scope depend on the conservation practice in which the contract is enrolled. This includes prescribed burns, tree thinning, and one-time grazing outside the primary bird nesting season. </p>
                  <h4>Maintenance</h4>
                  <p>Maintenance is an activity or practice not scheduled within the contract period but which may be necessary to keep the property in the condition required for the contract. Maintenance practices are required when problems occur, such as retaining vegetation cover to reduce erosion, the removal of noxious weeds/invasive species, and the installation of fire breaks as needed. These actions must be pre-approved in the conservation plan and are implemented out-of-pocket by the owner or operator of the property.</p>
                </Panel>
                <Panel
                  header="What is considered Highly Erodible Land?"
                  key="7"
                >
                  <p>
                    The USDA considers Highly Erodible Land to be land
                    associated with the acreage offered for CRP contract that
                    has an Erodibility Index (EI) greater than 8. An EI is an
                    index that measures the potential erodibility of the soil
                    from wind or water as it relates to the potential tolerance
                    of soil loss on the enrolled acres. Soil loss tolerance (T)
                    is the maximum average annual erosion rate that will not
                    negatively impact the soil’s long-term productivity.
                  </p>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => showModal("Highly Erodible Lands.pdf")}
                    >
                      <FilePdfOutlined />
                      Highly Erodible Lands
                    </Button>
                  </div>
                </Panel>
                <Panel header="What is a Primary Nesting Season?" key="8">
                  <p>
                    A Primary Nesting Season, is a regionally-specific range of
                    dates each year that preclude most management activities to
                    protect nesting birds that are in decline in the local area.
                  </p>
                </Panel>
              </Collapse>
            </div>

            <div className="crp-basics-group">
              <h3 className="crp-basics-group-title">Other CRP Initiatives</h3>
              <Collapse className="mt-3 crp-basics-paragraph">
                <Panel
                  header="What is the State Acres for Wildlife Enhancement initiative?"
                  key="1"
                >
                  <div>
                    <p>
                      The State Acres for Wildlife Enhancement (SAFE) Initiative
                      restores vital habitat to meet high-priority state
                      wildlife conservation goals. Through SAFE, landowners
                      establish wetlands, grasses, and trees. These practices
                      are designed to enhance important wildlife populations by
                      creating critical habitat and food sources. They also
                      protect soil and water health by working as a barrier to
                      sediment and nutrient run-off before they reach waterways.
                    </p>
                    <p>
                      Target species and accepted practices are determined by
                      and vary between States. Species targeted by SAFE include
                      those designated by the U.S. Fish and Wildlife Service as
                      threatened or endangered including the lesser prairie
                      chicken, those that have suffered population decline –
                      like the New England cottontail, bobwhite quail, and
                      grassland birds. SAFE also provides habitat for other
                      wildlife, including deer, game birds such as pheasants,
                      waterfowl, and pollinators that have significant social
                      and economic value.
                    </p>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => showModal("Safe Initiative Factsheet.pdf")}
                    >
                      <FilePdfOutlined />
                      Safe Initiative Factsheet
                    </Button>
                  </div>
                </Panel>
                <Panel
                  header="What is the Highly Erodible Land Initiative (HELI)?"
                  key="2"
                >
                  <p>
                    The Highly Erodible Land Initiative, or HELI, is
                    specifically for eligible cropland that is very vulnerable
                    to erosion, with an Erodibility Index greater than 20 on the
                    potential contract acreage. HELI contracts are 10 years in
                    length and establish either tree or grass cover to maintain
                    soil stability and help protect croplands and adjacent
                    streams from the effects of soil erosion. Landowners enroll
                    into one or more of eligible grass, tree, and wildlife
                    habitat practices, and can receive a 50% cost-share on
                    practice establishment expenses.
                  </p>
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        showModal("Highly Erodible Lands Initiative.pdf")
                      }
                    >
                      <FilePdfOutlined />
                      Highly Erodible Lands Initiative
                    </Button>
                  </div>
                </Panel>
                <Panel header="What is the CLEAR30 Initiative?" key="3">
                  <p>
                    CLEAR30 stands for the “Clean Lakes, Estuaries, And Rivers”
                    initiative that is now available as part of CRP nationwide.
                    Landowners can voluntarily re-enroll existing CRP or CREP
                    (Conservation Reserve Enhancement) cropland or marginal
                    pastureland contracts that have eligible water quality
                    practices into 30-year contracts. In addition to soil rental
                    payments, contracts will also come with a 27.5% Rental Rate
                    enhancement, 20% Water Quality Incentive payment, and
                    Climate Smart Incentive payments if associated with a
                    particular practice. Note required practice maintenance
                    activities include a 75% maintenance incentive payment.
                  </p>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => showModal("FSA Clear30.pdf")}
                    >
                      <FilePdfOutlined />
                      Clear30
                    </Button>
                  </div>
                </Panel>
                <Panel
                  header="What is the Conservation Reserve Enhancement Program (CREP)"
                  key="4"
                >
                  <p>
                    The Conservation Reserve Enhancement Program, or CREP, is
                    administered under the CRP and targets specific regions of
                    significant Highly conservation concern in a public-private
                    partnership with USDA, State and Tribal Governments and
                    Non-Governmental Organizations. CREP is a voluntary
                    incentive program with a contract period ranging from 10 –
                    15 years as specified in the contract agreement. Landowners
                    remove crop or ranchland that is considered environmentally
                    sensitive from production and establish conservation cover.
                    The aim of CREP is to focus incentives in areas where
                    substantial gains in natural resource benefits (e.g.,
                    wildlife habitat, water, soil, air quality, wetlands and
                    more). Active CREP project areas can be found
                    <a href="https://www.fsa.usda.gov/programs-and-services/conservation-programs/conservation-reserve-enhancement/crep_for_producers/index">
                    &nbsp;<u>here.</u>
                    </a>
                  </p>
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        showModal("Conservation Reserve Enhacement Program.pdf")
                      }
                    >
                      <FilePdfOutlined />
                      Conservation Reserve Enhacement Program
                    </Button>
                  </div>
                </Panel>
                <Panel header="What is Grassland CRP (GCRP)?" key="5">
                  <p>The Grassland Conservation Reserve Program (GCRP) is a voluntary program to protect grassland plant and animal biodiversity and plant species of conservation concern, while also supporting haying and grazing operations. Producers can graze, harvest hay, and produce seed, provided they maintain and/or enhance their permanent cover as required by their conservation plan. Contract durations are between 10 and 15 years, with cost-share assistance of up to 50% for infrastructure to facilitate grazing distribution. The annual rental payments for GCRP are 75% of the current year National Agricultural Statistics Service (NASS) pastureland rental rates.</p>
                </Panel>
                <Panel header="What is the Farmable Wetlands Program?" key="6">
                  <p>The Farmable Wetlands Program (FWP) is a voluntary program designed to restore the functions and values of previously farmed wetlands and wetland buffers. Participants agree to restore the wetland hydrology and plant cover to the maximum extent possible and to not use enrolled land for commercial purposes. Contracts are between 10 and 15 years and require enrollment of an associated wetland buffer CRP Practice to ensure functions and values of wetlands are maintained for the length of the contract.</p>
                </Panel>
                <Panel header="What is the Wellhead Protection Area?" key="7">
                  <p>Land located within 2,000 feet of public wellheads may be eligible for CRP continuous signup through wellhead protection area provisions. Eligible cropland must be within states that have an EPA-approved Wellhead Protection Program, and at least 51% of the total acreage must be located within a state-designated wellhead protection area for water being drawn for public use. CRP contracts enrolled though a Wellhead Protection Program are eligible for Practice and Sign-up Incentive Payments.</p>
                </Panel>
              </Collapse>
            </div>
          </div>
          <Modal
            centered
            title=""
            open={isModalOpen}
            onCancel={handleCancel}
            width="80%"
            footer={null}
          >
            <iframe
              className="embed-responsive-item_basics"
              src={"/Practices/" + fileToOpen}
              allowFullScreen
            />
          </Modal>
        </Col>
      </Row>

      <Row>
        <Col className="mt-3" span={20} offset={2}>
          <img className="crp-basics-photo" src={Fieldbanner} />
          <p className="crp-basics-photo-label">
            CP 33- Habitat Buffers for Upland Birds
          </p>
        </Col>
      </Row>

      <Row>
        <Col className="mt-3 crp-basics-paragraph" span={20} offset={2}>
          <h3>More Information</h3>
          <p>
            For more information on FSA programs and land eligibility, please
            visit{" "}
            <a
              className="externel-link"
              href="https://fsa.usda.gov"
              target="_blank"
            >
              fsa.usda.gov
            </a>
            .
          </p>
          <p>
            To locate your local FSA office, please visit{" "}
            <a
              className="externel-link"
              href="https://farmers.gov/service-locator"
              target="_blank"
            >
              farmers.gov/service-locator
            </a>
            .
          </p>
        </Col>
      </Row>

      <Footer />
    </>
  );
}
export default CRPBasics;
