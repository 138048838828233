import React, { useEffect, useState } from "react";
import { Col, Row, Menu, Modal, notification, Dropdown, Space } from "antd";
import {
  UnorderedListOutlined,
  UserOutlined,
  LogoutOutlined,
  ProfileOutlined,
  InteractionOutlined,
  DownOutlined,
} from "@ant-design/icons";
import hostURL from "../../Common/constants";
import logo from "../../images/header/HORIZONTAL_web_maroon.png";
import "./style.css";

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [toShowSpinner, setToShowSpinner] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const currentPage = window.location.href;
  const isLoggedIn = localStorage.getItem("token");

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };
  const activeMenu = window.location.pathname.substring(1);
  const items = [
    {
      label: <a href="/">Home</a>,
      key: "home",
      style: { fontWeight: "bold" },
      className: activeMenu === "" ? "activeMenu" : "",
    },
    {
      label: <a href="/CRPMenu">About</a>,
      key: "crpMenu",
      style: { fontWeight: "bold" },
      className: activeMenu === "CRPMenu" ? "activeMenu" : "",
    },
    {
      label: <a href="/CRPBasics">CRP Basics</a>,
      key: "crpBasics",
      style: { fontWeight: "bold" },
      className: activeMenu === "CRPBasics" ? "activeMenu" : "",
    },
    {
      label: <a href="/PracticeList">Explore Practices</a>,
      key: "explorePractices",
      style: { fontWeight: "bold" },
      className: activeMenu === "PracticeList" ? "activeMenu" : "",
    },
    // {
    //   label: <a href="/Map">Check Eligibility</a>,
    //   key: "checkEligibility",
    //   style: { fontWeight: "bold" },
    //   className: activeMenu == "Map" ? "activeMenu" : ""
    // },
    {
      label: <a href="/Workshops">Workshops</a>,
      key: "workshops",
      style: { fontWeight: "bold" },
      className: activeMenu === "Workshops" ? "activeMenu" : "",
    },
    {
      label: <a href="/ProjectTeam">Team</a>,
      key: "projectTeam",
      style: { fontWeight: "bold" },
      className: activeMenu === "ProjectTeam" ? "activeMenu" : "",
    },
    {
      label: <a href="/Contact">Contact Us</a>,
      key: "contact",
      style: { fontWeight: "bold" },
      className: activeMenu === "Contact" ? "activeMenu" : "",
    },
  ];

  const userMenuItems = [
    {
      label: (
        <button>
          <ProfileOutlined /> Profile
        </button>
      ),
      key: "uhome",
      style: { fontWeight: "bold" },
    },
    {
      label: (
        <a href="/PastActivity">
          <InteractionOutlined /> Past Activity
        </a>
      ),
      key: "ucrpMenu",
      style: { fontWeight: "bold" },
    },
    {
      label: (
        <button onClick={() => showLogoutModal()}>
          <LogoutOutlined /> Logout
        </button>
      ),
      key: "ucrpMenu",
      style: { fontWeight: "bold" },
    },
  ];

  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutOk = () => {
    Signout();
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectedMenuItem = (itemData) => {
    setSelectedItem(itemData.selectedKeys);
  };

  const Signout = async () => {
    setToShowSpinner(true);
    await fetch(hostURL + "api/users/signOut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(isLoggedIn)
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);
        if (status === 200) {
          openNotificationWithIcon("success", "Success", message);
          document.cookie =
            "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.removeItem("token");
          localStorage.removeItem("userRef");
          window.location.assign("/");
        } else openNotificationWithIcon("error", "Error", message);
      });
  };

  useEffect(() => {
    console.log(currentPage);
  });

  return [
    <>
      <div className="msu_header">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 10, offset: 1 }}>
            {isLoggedIn && isLoggedIn.length > 24 ? (
              <>
                <Dropdown.Button
                  className="loggedInMenu"
                  icon={<DownOutlined />}
                  menu={{
                    items: userMenuItems,
                  }}
                >
                  <UserOutlined />
                </Dropdown.Button>
              </>
            ) : (
              <a href="/">
                <h1
                  className={
                    currentPage.slice(-1) === "/"
                      ? "header-tool-name header-tool-name-mobile"
                      : "header-tool-name"
                  }
                >
                  CRP Menu Tool
                </h1>
              </a>
            )}
          </Col>
          <Col
            className={currentPage.slice(-1) === "/" ? "" : "gri-logo"}
            xs={{ span: 24 }}
            lg={{ span: 10, offset: 2 }}
          >
            <a
              href="https://www.gri.msstate.edu/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="header-logo" src={`${logo}`} alt="MSU Logo" />
            </a>
            {/* <h6 className="register-login-link">
              <a href="/UserLogin">
                <UserOutlined /> Register / Login
              </a>
            </h6> */}
          </Col>
        </Row>
      </div>
      <Row className="header-nav">
        <Col xs={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
          <button className="crpMobileNavIcon" onClick={showModal}>
            <UnorderedListOutlined />
          </button>
          <Menu
            className="crpmainnav"
            mode="horizontal centered"
            items={items}
            onSelect={selectedMenuItem}
            breakpoint=""
            defaultSelectedKeys={items[0]}
            selectedKeys={[selectedItem]}
          />
          <Modal
            title="Menu"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <Menu mode="inline" items={items} />
          </Modal>
        </Col>
      </Row>
      <>
        <Modal
          title="Logout Confirmation"
          open={isLogoutModalOpen}
          onOk={handleLogoutOk}
          onCancel={handleLogoutCancel}
          okText="Ok"
          cancelText="Cancel"
        >
          <p>
            <center>Are you sure to log out?</center>
          </p>
        </Modal>
      </>
      {contextHolder}
    </>,
  ];
}

export default Header;
