import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Map, { Source, Layer } from "react-map-gl";
import bbox from "@turf/bbox";
import "./style.css";

function FilterMap({
  setClickedState,
  setClickedCounty,
  setClickedFips,
  selectedFips,
}) {
  const mapRef = useRef();
  const stateFilter = [
    "match",
    ["get", "STATE"],
    ["IL", "MO", "MS"],
    true,
    false,
  ];
  const countyFilter = ["==", "FIPS", selectedFips];

  const onMapClick = useCallback((event) => {
    if (event.features.length > 0) {
      const county = event.features[0];
      setClickedState(county.properties.STATE);
      setClickedCounty(county.properties.COUNTYNAME);
      setClickedFips(county.properties.FIPS);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      console.log("start");
      let featureTest = mapRef.current.querySourceFeatures("map-source", {
        sourceLayer: "c_08mr23-1pp4eg",
        filter: ["==", "FIPS", selectedFips],
      });
      if (featureTest.length === 0) {
        mapRef.current.fitBounds(
          [
            [-95.6, 30.3],
            [-89.1, 41.3],
          ],
          { padding: 40, duration: 1000 }
        );
      }
      setTimeout(() => {
        var features = mapRef.current.querySourceFeatures("map-source", {
          sourceLayer: "c_08mr23-1pp4eg",
          filter: ["==", "FIPS", selectedFips],
        });
        if (features.length) {
          // calculate the bounding box of the feature
          const [minLng, minLat, maxLng, maxLat] = bbox(features[0]);

          mapRef.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          );

          console.log("end"); //For testing when the "Next" button should be disabled
        }
      }, "1000");
    }
  }, [selectedFips]);

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
      initialViewState={{
        longitude: -91,
        latitude: 36,
        zoom: 4,
      }}
      //Anthony - changed style and onRender to resize the map without distortion
      style={{ width: "60vw", height: "50vh" }}
      mapStyle="mapbox://styles/mapbox/light-v9"
      onRender={(event) => event.target.resize()}
      onClick={onMapClick}
      interactiveLayerIds={["county-boundary"]}
    >
      <Source
        id="map-source"
        type="vector"
        url="mapbox://chuck0520.5pq9omti"
        maxzoom={22}
        minzoom={0}
      >
        <Layer
          id="county-boundary"
          source-layer="c_08mr23-1pp4eg"
          type="fill"
          paint={{
            "fill-outline-color": "#800000",
            "fill-color": "#800000",
            "fill-opacity": 0.2,
          }}
          minzoom={0}
          maxzoom={22}
          filter={stateFilter}
        />
        <Layer
          id="county-highlight"
          source-layer="c_08mr23-1pp4eg"
          type="fill"
          paint={{
            "fill-outline-color": "#800000",
            "fill-color": "#800000",
            "fill-opacity": 0.4,
          }}
          minzoom={0}
          maxzoom={22}
          filter={countyFilter}
        />
        <Layer
          id="county-labels"
          type="symbol"
          source="map-source"
          source-layer="c_08mr23-1pp4eg"
          layout={{
            "text-field": ["get", "COUNTYNAME"],
            "text-size": {
              stops: [
                [0, 0],
                [4, 0],
                [6, 10],
                [8, 15],
              ],
            },
          }}
          paint={{ "text-color": "#800000" }}
          filter={stateFilter}
        />
      </Source>
    </Map>
  );
}
export default FilterMap;
