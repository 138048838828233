import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { Col, Row, Table, Spin, notification } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";
import myCommonApiCall from "../../Common/apicalls";
import hostURL from '../../Common/constants';

const authToken = localStorage.getItem("token");
const userId = localStorage.getItem("userRef");

function PastActivity() {
  const [loading, setLoading] = useState(false);
  const [pastSearches, setPastSearches] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message
    });
  };

  const columns = [
    {
      title: "Route",
      dataIndex: "route"
    },
    {
      title: "subRoute",
      dataIndex: "subroute"
    },
    {
      title: "Searched On",
      dataIndex: "searchedon"
    }
  ];

  useEffect(() => {
    setLoading(true);
    fetch(hostURL+"api/usersearchdata/" + userId, {
      // credentials: 'include',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken
      }
    })
    .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;

        if (status == 200) {
        const itemsData = json.map((item) => {
            const formData= JSON.parse(item.formData);
            const checkedOptions = formData.toSendData && Object.keys(formData.toSendData).join(', ');
          const childrenData = `<p>Selected State: <b>${
            formData.selectedState
          }</b>, Selected County: <b>${formData.selectedCounty}</b> </p><p> Selected Options : ${checkedOptions} </p>`;
          return {
            key: item.id,
            route: item.route,
            subroute: item.subRoute,
            data: parse(childrenData),
            searchedon: item.createdAt
          };
        });
        setPastSearches(itemsData);
        setLoading(false);
    }else if(status==401)
        myCommonApiCall.signOut();
    else
    openNotificationWithIcon("error", "Error", message);
      })
  }, []);

  const rowSelection = {
    type: 'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    }
  };

  return (
    <>
      <Header />
      <Spin spinning={loading}>
      <Row >
        <Col span={20} offset={2}>
            <h3> Past Searches</h3>

          <p><Table
            // rowSelection={rowSelection}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0
                  }}
                >
                  {record.data}
                </p>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable"
            }}
            dataSource={pastSearches}
            bordered
            pagination={{
                pageSize: 30,
              }}
              scroll={{
                y: window.screen.height-300,
              }}
          />
          </p>
        </Col>
      </Row>
      </Spin>
      <Footer />
      {contextHolder}
    </>
  );
}
export default PastActivity;