import hostURL from './constants';
const authToken =  localStorage.getItem('token');

let myCommonApiCall=[];
myCommonApiCall.storeSearchData = async (values, sroute)=>{

    const formData = { 
      userId: localStorage.getItem('userRef'),
      route: window.location.href,
      subRoute: sroute,
      formData:JSON.stringify(values)
    };

    // setLoading(true);
   await fetch(hostURL+"api/usersearchdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:  authToken,
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then((json) => {
        // setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  myCommonApiCall.signOut= async()=>{
    await fetch(hostURL+"api/users/signOut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        if (status == 200) {
          document.cookie =
            "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.removeItem("token");
          localStorage.removeItem("userRef");
          window.location.assign("/UserLogin");
        }
      });
  }

  export default myCommonApiCall;