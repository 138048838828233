import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map, { Source, Layer } from 'react-map-gl';
import { Button, Card, Collapse, Col, Row, Select } from "antd";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { LuMap, LuSatellite } from "react-icons/lu";
import bbox from '@turf/bbox';
import { ModuleContext } from "../../context/Context";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import './style.css';

const FieldReport = ({ setPracticeNumber }) => {
  const [items, setItems] = useState([]);
  const [majorSoilTypes, setMajorSoilTypes] = useState([]);
  const [fieldSize, setFieldSize] = useState(null);
  const [fieldRentalRate, setFieldRentalRate] = useState(null);
  const [baseLayer, setBaseLayer] = useState("mapbox://styles/mapbox/light-v9");
  const navigate = useNavigate();
  const mapRef = useRef();
  const stateFilter = ["match", ["get", "STATE"], ["IL", "MO", "MS"], true, false];
  const {fieldDetails, practicePreference, priorityPreference} = useContext(ModuleContext);
  const fieldBasics = fieldDetails.countyRate.rows[0];
  const fieldFeatureData = { type: "Feature", geometry: JSON.parse(fieldBasics.geometry) };

  const print = () => {
    window.print();
  };
  
  const getFieldRentalRate = () => {
    const fieldRate = fieldDetails.fieldRateIL1.rows.length > 0 ?
      fieldDetails.fieldRateIL1 : 
      (fieldDetails.fieldRateIL2.rows.length > 0 ?
        fieldDetails.fieldRateIL2 :
        (fieldDetails.fieldRateMO.rows.length > 0 ?
          fieldDetails.fieldRateMO : fieldDetails.fieldRateMS
        )
      );
    const fieldSpecifics = fieldRate.rows;
    const soilTypes = [...new Set(fieldSpecifics.map((field) => field.musym))];

    const SoilTypeData = soilTypes.map((soilType) => {
      const fieldsBySoilType = fieldSpecifics.filter((field) => field.musym === soilType);
      const srr = fieldsBySoilType[0].srr;
      const areas = fieldsBySoilType.map((field) => field.area);
      const getTotalArea = (total, area) => {
        return total + area
      };
      const totalArea = areas.reduce(getTotalArea, 0);
      return {type: soilType, srr: srr, totalArea: totalArea};
    });

    const getTopThree = (arr) => {
      arr.sort((a, b) => a < b ? 1 : a > b ? -1 : 0);
      return(arr.slice(0, 3));
    };

    const totalAreas = SoilTypeData.map((soilType) => soilType.totalArea);
    const topThreeTotalAreas = getTopThree(totalAreas);
    const topThreeSoilTypes = SoilTypeData.filter((soilType) => 
      topThreeTotalAreas.includes(soilType.totalArea)
    );
    setMajorSoilTypes(topThreeSoilTypes);
  };
  
  useEffect(() => {
    setFieldSize(fieldBasics.area*0.000247105);
    getFieldRentalRate();
  }, [fieldDetails]);

  useEffect(() => {
    const rateBySoilTypes = majorSoilTypes.map((soilType) => {
      const areaInAcre = soilType.totalArea*0.00024710;
      const rateBySoilType = soilType.srr*areaInAcre;
      return rateBySoilType;
    });
    const getTotalRate = (total, rate) => {
      return total + rate
    };
    const totalRentalRate = Math.round(rateBySoilTypes.reduce(getTotalRate, 0));
    setFieldRentalRate(totalRentalRate);
  }, [majorSoilTypes])

  useEffect(() => {
    if (mapRef.current) {
      const [minLng, minLat, maxLng, maxLat] = bbox(fieldFeatureData);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {padding: 40, duration: 1000}
      );
    };
  }, [fieldFeatureData]);

  useEffect(() => {
    if (practicePreference) {
      const practiceDetails = practicePreference.practices.map((practice) => {
        return ({
          key: practice.title,
          label: (
            <>
              <label>{practice.title}</label>
              <input type="checkbox" style={{float: "right"}}/>
            </>
          ),
          children: (
            <>
              <p>{practice.note}</p>
              <p>
                <a
                  href={`/Practice/${practicePreference.practiceList.fips}/${btoa(
                    practice.pdflink
                  )}/${btoa(practice.title)}`}
                  target="_blank"
                  className="btn btn-primary small-maroon-btn"
                >
                  Details
                </a>
              </p>
            </>
          )
        })
      });
      setItems(practiceDetails);
    }
  }, [practicePreference]);

  // For unit conversion, 1 square meter = 0.000247105 acres

  return (
    <>
      <Header />
      <div className="report">
        <h2 className="report-header">CRP Field Eligibility Report</h2>
        <Button className="report-print-button" onClick={print}>Print</Button>
        <hr className="report-hr"/>
        <Row>
          <Col span={12}>
            <h3 className="report-section-header">Your Basic Information:</h3>
            <div className="report-basic-information">
              <p>
                <b>State: </b>
                {fieldBasics.state}
              </p>
              <p>
                <b>County: </b>
                {fieldBasics.county}
              </p>
              <p>
                <b>FIPS Code: </b>
                {fieldBasics.fips}
              </p>
              <p>
                <b>Address: </b>
                Not Specified
              </p>
              <p>
                <b>Field Size: </b>
                {Math.round(fieldSize*100)/100} acre
              </p>
              <p>
                <b>Field Land Cover Type: </b>
                {
                  priorityPreference.landCoverTypes.length ? 
                  priorityPreference.landCoverTypes.map((landCoverType, index) => {
                    if (index === 0) {
                      return landCoverType
                    } else {
                      return ", " + landCoverType
                    }
                  }) :
                  "Not Specified, please go back to explore CRP practices"
                }
              </p>
              <p>
                <b>Field Base Rental Rate In Total*: </b>
                ${fieldRentalRate}
              </p>
              <p>
                <b>Field Base Rental Rate Per Acre*: </b>
                ${Math.round(fieldRentalRate/fieldSize*100)/100}/acre
              </p>
              <p>
                <b>County Average Rental Rate Per Acre*: </b>
                ${Math.round(fieldBasics.count_2023*100)/100}/acre
              </p>
              <p>
                <b>Within SAFE Zone: </b>
                {fieldDetails.safeArea.rows.length ? "Yes" : "No"}
              </p>
              <p>
                <b>Within Floodplain: </b>
                {fieldDetails.floodPlain.rows.length ? "Yes" : "No"}
              </p>
              <p>
                <b>Selected CRP Priorities: </b>
                {
                  priorityPreference.priorities.length ? 
                  priorityPreference.priorities.map((priority, index) => {
                    if (index === 0) {
                      return priority
                    } else {
                      return ", " + priority
                    }
                  }) :
                  "No priority selected, please go back to explore CRP practices"
                }
              </p>
              <p>
                <b>Available CRP Practices: </b>
                {
                  practicePreference ? 
                  practicePreference.practices.map((practice, index) => {
                    if (index === 0) {
                      return practice.title
                    } else {
                      return ", " + practice.title
                    }
                  }) :
                  "No available practices, please go back to explore CRP practices"
                }
              </p>
              {
                practicePreference ?
                <></> :
                <Button onClick={() => navigate("/PracticeList")}>
                  <GrLinkPrevious />
                  <b> To Explore CRP Practices</b>
                </Button>
              }
            </div>
          </Col>
          <Col span={12}>
            <h3 className="report-section-header">Your Field Boundary:</h3>
            <div className="report-map">
              <Map
                ref={mapRef}
                mapboxAccessToken="pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                initialViewState={{
                  longitude: -91,
                  latitude: 36,
                  zoom: 4
                }}
                style={{width: "28vw", height: "50vh"}}
                mapStyle={baseLayer}
              >
                <Button className="layer-button" onClick={() => setBaseLayer("mapbox://styles/mapbox/light-v11")}>
                  <LuMap />
                </Button>
                <Button className="layer-button" onClick={() => setBaseLayer("mapbox://styles/mapbox/satellite-streets-v12")}>
                  <LuSatellite />
                </Button>
                <Source
                  id="map-source"
                  type="vector"
                  url="mapbox://chuck0520.5pq9omti"
                  maxzoom={22}
                  minzoom={0}
                >
                  <Layer
                    id="county-boundary"
                    source-layer="c_08mr23-1pp4eg"
                    type="line"
                    paint={{
                      "line-color": "#800000",
                      "line-width": 1,
                      "line-opacity": 0.4
                    }}
                    minzoom={0}
                    maxzoom={22}
                    filter={stateFilter}
                  />
                </Source>
                <Source id="field-source" type="geojson" data={fieldFeatureData}>
                  <Layer
                    id="field"
                    type="fill"
                    paint={{
                      "fill-outline-color": "#800000",
                      "fill-color": "#800000",
                      "fill-opacity": 0.6,
                    }}
                  />
                </Source>
              </Map>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <h3 className="report-section-header">Your Available Practices with Corresponding Incentives:</h3>
            <div className="report-practice-list-short">
              <Collapse items={items} />
            </div>
          </Col>
          <Col span={12}>
            <h3 className="report-section-header">Your Estimated Rental Rate with Selected CRP Practice*:</h3>
            <div className="report-practice-list-short">
              <Collapse
                items={[{
                  key: "rate",
                  label:"The Estimated Rental Rate for Your Field is $" + fieldRentalRate + "*",
                  children: (
                    <p>
                      {/* Your top three soil types are A, B, C.
                      There is a acre of A, b acre of B, c acre of C within your field.
                      Based on our calculation, your adjusted field rental rate would be:
                      $(a*A + b*B + c*C)*incentives. */}
                      Based on the size of your field,
                      your top three soil types are {" "}
                      <b>
                      {
                        majorSoilTypes ? 
                        majorSoilTypes.map((soilType, index) => {
                          if (index === 0) {
                            return (
                              soilType.type + "($" +
                              soilType.srr + "/acre, " +
                              Math.round(soilType.totalArea*0.00024710*100)/100 + " acre)"
                            );
                          } else {
                            return (
                              ", " +
                              soilType.type + "($" +
                              soilType.srr + "/acre, " +
                              Math.round(soilType.totalArea*0.00024710*100)/100 + " acre)"
                            );
                          }
                        }) :
                        " "
                      }
                      </b>.
                      your total adjusted field rental rate is: 
                      <b> ${fieldRentalRate}</b>*.
                      your adjusted field rental rate per acre is:
                      <b> ${Math.round(fieldRentalRate/fieldSize*100)/100}/acre</b>*.
                    </p>
                  )
                }]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <h3 className="report-section-header">Your Local USDA Office:</h3>
            <div className="report-usda-information">
              <p>Please reach out to your local USDA office for further information.</p>
              <a
                  href="https://www.farmers.gov/working-with-us/service-center-locator"
                  target="_blank"
                  className="btn btn-primary small-maroon-btn"
                >
                  Find Your Local USDA Office
              </a>
              {/* <p>
                <b>Starkville Service Center Rural Development</b>
              </p>
              <p>
                <b>Phone Number: </b>
                (662) 320-4009
              </p>
              <p>
                <b>Physical Address: </b>
                510 Highway 25 North
                Starkville, MS 39759
              </p>
              <p>
                <b>Mailing Address: </b>
                Mailing Address not available
              </p>
              <p>
                <b>Contact: </b>
                Clifton Russel
                clifton.russel@usda.gov
              </p> */}
            </div>
          </Col>
          <Col span={12}>
            <h3 className="report-section-header">Disclaimer:</h3>
            <div className="report-usda-information">
              <p>
                *Please note that the estimated rental rate is a rough estimation based on the CRP practice you selected.
                It may vary due to your regional USDA official's final decision.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default FieldReport;