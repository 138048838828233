import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  Card,
  Select,
  Modal,
  notification,
} from "antd";
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";
import { DoubleRightOutlined } from "@ant-design/icons";

function RegistrationPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toShowSpinner, setToShowSpinner] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const onFinish = (values) => {
    setToShowSpinner(true);
    const formData = {
      firstname: values.firstname,
      lastname: values.lastname,
      emailid: values.email,
      username: values.uname,
      password: values.password,
      phone: values.phone,
      organization: values.organization,
      affiliation: values.affiliation.toString(),
      zipcode: values.zipcode,
      createdAt: new Date().toLocaleDateString(),
    };

    fetch(hostURL + "api/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);

        if (status == 200) {
          openNotificationWithIcon("success", "Success", message);
          window.location.assign("/UserLogin");
        } else openNotificationWithIcon("error", "Error", message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Header />
      {contextHolder}
      <Row>
        <Col span={12} offset={6}>
          <Card title="Registration" className="registrationForm">
            <Form
              name="registrationForm"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="cnfemail"
                label="Confirm Email"
                dependencies={["email"]}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please re input your Email ID!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("email") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The Email Id that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    max: 10,
                    min: 10,
                    message: "Please input your Contact number!"
                  }
                ]}
              >
                <Input addonBefore="+1" maxLength={10} />
              </Form.Item> */}
              <Form.Item label="Zipcode" name="zipcode">
                <Input />
              </Form.Item>

              <Form.Item label="Affiliation" name="affiliation">
                <Select mode="multiple" allowClear>
                  <Select.Option value="">Choose...</Select.Option>
                  <Select.Option value="landowner">Landowner</Select.Option>
                  <Select.Option value="usdastaff">USDA Staff</Select.Option>
                  <Select.Option value="conversationpartner">
                    Conversation Partner
                  </Select.Option>
                  <Select.Option value="academic">
                    Academic / Research
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="organization"
                label="Organization"
                rules={[
                  {
                    type: "string",
                    message: "Please input your Organization!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    min: 6,
                    message: "Please input your password, Min 6 Char",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="cnfpassword"
                dependencies={["password"]}
                label=" Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please re input your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The Password that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="isTermsAccepted"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Should accept Terms & Conditions")
                          ),
                  },
                ]}
              >
                <Checkbox>
                  I have read the{" "}
                  <button onClick={showModal} className="textBlack readTerms">
                    Terms & Conditions
                    <DoubleRightOutlined />
                  </button>
                </Checkbox>
              </Form.Item>
              <Space>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  loading={toShowSpinner}
                >
                  Submit
                </Button>
                <Button
                  className="login-form-button"
                  htmlType="reset"
                  disabled={toShowSpinner}
                >
                  Reset
                </Button>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer />
      <Modal
        title="Terms And Conditions"
        open={isModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleCancel()}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
}
export default RegistrationPage;
